import { IconsPath } from "../../utils/Server";

export const DEMO_USERS = [69, 73, 74, 75, 76, 79, 82, 85, 86, 90, 238];

export const PLAYER_CONFIG = {
  file: {
    attributes: {
      controlsList: "nodownload",
    },
  },
};

export const RAZORPAY_KEY = "rzp_test_LzFQeal6Iyc1gs";

export const TASK_ID = {
  BRAIN_DRAIN: 1,
  METRIC_1: 2,
  DIMENSION: 3,
  REFLECTION: 4,
  METRIC_2: 5,
  METRIC_3: 6,
  INCOGNITO: 7,
  MIXER: 8,
  SECRET_WORDS: 9,
  SEATING: 10,
  MASTER_MIND: 11,
  GUESS_WHO: 12,
  HANDWRITING: 13,
  TEMPO: 14,
  TWO_TUNES: 15,
  ENACT: 16,
  NAME_NARRATE: 17,
  FIXIT: 18,
  WHATS_NEXT: 19,
  PIANO: 20,
  COGNIZANT: 21,
};

export const TASK_ICONS = {
  1: IconsPath + "Brain_drain.png", // Brain drain
  2: IconsPath + "Metrics_01.png", // Survey
  3: IconsPath + "Dimension_01.png", // Dimensions
  4: IconsPath + "Reflection.png", // Situation based
  5: IconsPath + "Metrics_02.png", // Inter
  6: IconsPath + "Metrics_03.png", // Intra
  7: IconsPath + "Incognito.png", // Incognito
  8: IconsPath + "Mixer_01.png", // Mixer
  9: IconsPath + "Secret+word.png", // Secret words
  10: IconsPath + "Seating.png", // Seating
  11: IconsPath + "Mastermind.png", // Master mind
  12: IconsPath + "Enact.png", // Guess who
  13: IconsPath + "Introtext.png", // Handwriting analysis
  14: IconsPath + "tempochange.jpeg", // Tempo change
  15: IconsPath + "Tunes.png", // Two tunes
  16: IconsPath + "Enact.png", // Enact
  17: IconsPath + "NameandNarrate_03.png", // Name and narrate
  18: IconsPath + "Fixit.png", // Fixit
  19: IconsPath + "Whats_Next.png", // Whats next
  20: IconsPath + "Piano_code.png", // Piano
  21: IconsPath + "Cognizant.png", // Cognizant
};

export const NON_EDITABLE_TASKS = [3, 9, 10, 11, 13, 18, 19, 20];

export const TIMER_TASKS = [1, 3, 8, 9, 10, 11, 13, 14, 15, 18, 19, 20];

export const STATE_KEYS = {
  ANSWERS: "answers",
  VISITED: "visited",
  ERROR: "error",
  QUESTION: "question",
};

export const WAITING_TIME = {
  ICON_LOADING: window.location.toString().includes("localhost") ? 500 : 5000,
  TASK_LOADING: window.location.toString().includes("localhost") ? 1000 : 10000,
};
