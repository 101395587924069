import React from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import SignUp from './components/signUp/signUp';
import SignIn from './components/signUp/signIn';
import TermsAndConditions from './components/termsConditions';
import './App.css';
import './Responsive.css';
import AuthRoutes from './routes/Routes';
import {connect} from 'react-redux';
import {SetAuthToken} from './utils/Server';
import {hideMessage, setAuthUser} from './redux/actions/Home';
import Images from './assets/images';

const RestrictRightClick = e => {
    if (e.keyCode === 123) {
        // F12
        e.preventDefault();
    } else if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        // ctrl + shift + I
        e.preventDefault();
    } else if (e.metaKey && e.shiftKey && e.keyCode === 67) {
        // command + shift + C
        e.preventDefault();
    } else if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
        // ctrl + shift + J
        e.preventDefault();
    } else if (e.ctrlKey && e.keyCode === 85) {
        // ctrl + shift + U
        e.preventDefault();
    }
};

class App extends React.Component {
    componentWillMount() {
        if (
            localStorage.getItem('auth_user') &&
            localStorage.getItem('auth_token')
        ) {
            SetAuthToken(localStorage.getItem('auth_token'));
            this.props.onSetAuthUser(
                JSON.parse(localStorage.getItem('auth_user')),
            );
        }

        // Disable right click, inspect element and F12 buttons from inspecting elements
        document.addEventListener('contextmenu', event =>
            event.preventDefault(),
        );
        document.addEventListener('keydown', e => RestrictRightClick(e));
    }

    componentDidUpdate() {
        // Hide the error or messages
        if (this.props.error !== '' || this.props.message !== '') {
            setTimeout(
                function () {
                    this.props.onHideMessage();
                }.bind(this),
                2400,
            );
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', event =>
            event.preventDefault(),
        );
        document.removeEventListener('keydown', e => RestrictRightClick(e));
    }

    render() {
        const {is_auth, main_loader, message, error} = this.props;

        return (
            <div className="container-fluid App">
                {main_loader && (
                    <div className="mainLoaderContainer">
                        <img
                            src={Images.loader}
                            height={50}
                            alt="Loading . . ."
                        />
                    </div>
                )}

                {is_auth ? (
                    <Router>
                        <Switch>
                            <AuthRoutes />
                        </Switch>
                    </Router>
                ) : (
                    <Router>
                        <Switch>
                            <Route exact path="/" component={SignIn} />
                            <Route
                                exact
                                path="/terms-conditions"
                                component={TermsAndConditions}
                            />
                            <Route exact path="/signup" component={SignUp} />
                        </Switch>
                    </Router>
                )}

                {/* Error or message display container */}
                <div className="message_error_container">
                    {message && message !== '' ? (
                        <span className="message_text">
                            {' '}
                            <i className="fa fa-check-circle"></i> {message}{' '}
                        </span>
                    ) : (
                        ''
                    )}
                    {error && error !== '' ? (
                        <span className="error_text">
                            {' '}
                            <i className="fa fa-exclamation-circle"></i> {error}{' '}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        main_loader: state.home.main_loader,
        error: state.home.error,
        message: state.home.message,
    };
};

const mapActionsToProps = {
    onSetAuthUser: setAuthUser,
    onHideMessage: hideMessage,
};

export default connect(mapStatesToProps, mapActionsToProps)(App);
