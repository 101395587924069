const Images = {
    avatar: require('./Avatar.png'),
    dimensionSample1: require('./dimensionSample1.png'),
    dimensionSample2: require('./dimensionSample2.png'),
    dimensionSample3: require('./dimensionSample3.png'),
    dimensionStandard1: require('./dimensionStandard1.png'),
    dimensionStandard2: require('./dimensionStandard2.png'),
    dimensionStandard3: require('./dimensionStandard3.png'),
    dimensionStandard4: require('./dimensionStandard4.png'),
    logo: require('./logo.png'),
    loader: require('./loader.gif'),
    dotLoader: require('./dot_loader.gif'),
    day: require('./day.png'),
    choose: require('./choose.png'),
    table: require('./table.png'),
    deleteWhite: require('./deleteWhite.png'),
    deleteBlack: require('./deleteBlack.png'),
    fixitSVG: require('./fixit/fixit-10.svg'),
    yes: require('./yes.png'),
    no: require('./no.png'),
    payment: require('./payment.png'),
};

export default Images;
