import {Server, ApiPaths, SetAuthToken} from '../../utils/Server';
import $ from 'jquery';

export const MAIN_LOAD_TRUE = 'MAIN_LOAD_TRUE';
export const MAIN_LOAD_FALSE = 'MAIN_LOAD_FALSE';
export const SUB_LOAD_TRUE = 'SUB_LOAD_TRUE';
export const SUB_LOAD_FALSE = 'SUB_LOAD_FALSE';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';

export const userRegister = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post(ApiPaths.REGISTER, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statusCode === 200)
                {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem('auth_user', JSON.stringify(data.data));
                    var token = data.data.accessToken;
                    localStorage.setItem('auth_token', token);
                    SetAuthToken(token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(data.data));
                    
                    window.location.href = "/home"; // Redirect to dashboard
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError(err.message));
            })
    }
}

export const userLogin = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post(ApiPaths.LOGIN, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statusCode === 200) {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem('auth_user', JSON.stringify(data.data));
                    var token = data.data.accessToken;
                    localStorage.setItem('auth_token', token);
                    SetAuthToken(token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(data.data));

                    window.location.href = "/home"; // Redirect to dashboard
                }
                else {
                    dispatch(displayError(data.message));
                }

                // console.log('Login response', response);
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError(err.message));
            })
    }
}

export const userLogout = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        // return Server.post('/logout')
        //     .then(response => {
        //         const { data } = response;
                // if(data.statusCode === 200)
                // {
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('auth_user');
                    SetAuthToken('');
                    dispatch(mainLoadingFalse());

                    dispatch(userLogoutSuccess());

                    window.location.href = "/";
            //     }
            // })
    }
}

export const updateProfile = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post(ApiPaths.UPDATE_PROFILE, data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statusCode === 200) {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem('auth_user', JSON.stringify(data.data));
                    dispatch(setAuthUser(data.data));

                    // console.log('Auth user', data.data);
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError(err.message));
            })
    }
}

export const updatePassword = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post('/update_password', data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statusCode === 200)
                {
                    dispatch(displayMessage(data.message));
                    $('#edit_pwd, #edit_new_pwd, #edit_cfrm_pwd').val('');
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            })
    }
}

// Send forgot password otp action
export const sendForgotPasswordOTP = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post('/send_forgot_password_otp', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statusCode === 200)
                {
                    dispatch(displayMessage(data.message));
                    dispatch(successStatus('OTP sent'));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}

// Update new forgotten password action
export const updateNewPassword = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post('/update_new_password', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const {data} = response;
                if(data.statusCode === 200)
                {
                    dispatch(displayMessage(data.message));
                    dispatch(successStatus('Password updated'));
                }
                else
                    dispatch(displayError(data.message));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            });
    }
}

export const mainLoadingTrue = () => {
    return {
        type : MAIN_LOAD_TRUE
    }
}

export const mainLoadingFalse = () => {
    return {
        type : MAIN_LOAD_FALSE
    }
}

export const subLoaderTrue = () => {
    return {
        type : SUB_LOAD_TRUE
    }
}

export const subLoaderFalse = () => {
    return {
        type : SUB_LOAD_FALSE
    }
}

export const displayMessage = (message) => {
    return {
        type : DISPLAY_MESSAGE,
        payload : {
            message : message
        }
    }
}

export const displayError = (error) => {
    return {
        type : DISPLAY_ERROR,
        payload : {
            error : error
        }
    }
}

export const hideMessage = () => {
    return {
        type : HIDE_MESSAGE,
    }
}

export const setAuthUser = (auth_user) => {
    return {
        type : SET_AUTH_USER,
        payload : {
            auth_user : auth_user
        }
    }
}

export const loginSuccess = () => {
    return {
        type : LOGIN_SUCCESS
    }
}

export const userLogoutSuccess = () => {
    return {
        type : LOGOUT_SUCCESS
    }
}

export const successStatus = (status) => {
    return {
        type : SUCCESS_STATUS,
        payload : {
            status : status
        }
    }
}
