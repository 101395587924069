import React from 'react';
import {Button} from '@material-ui/core';
import swal from 'sweetalert';
import { STATE_KEYS } from '../../../../assets/constants';

class SecretWord extends React.Component {

    constructor(props) {
        super(props);
        this.state = {alphabets: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'], vowels: ['A','E','I','O','U']};
    }

    handleQuestionAnswer = (questionID, questionWords, guessedWord, totalAttempts) => {
        var {userAnswers, taskTime} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questionID);
        var answer = [];
        var guessedWordIndex = 0;
        var guessedAlphabets = [];
        var rightPlace = false;
        var wrongPlace = false;
        var wordGuessed = false;

        // Get already saved answer
        if(alreadyExistsIndex >= 0) {
            answer = userAnswers[alreadyExistsIndex].answer;
            guessedAlphabets = userAnswers[alreadyExistsIndex].guessed;

            // Check for guessed words and there correct places if words reaches length of question
            if((guessedAlphabets.length >= questionWords.length) && ((guessedAlphabets.length % questionWords.length) === 0)) {

                // Get previous answers as same as question words length
                var questionWordsArray = questionWords;
                var previousAnswers = (guessedAlphabets.slice(guessedAlphabets.length - questionWords.length));

                // Compare previous answers with questions answers
                if(JSON.stringify(questionWordsArray) === JSON.stringify(previousAnswers)) {
                    wordGuessed = true;
                }
            }
        }

        // If already guessed correct words
        if(wordGuessed === false) {

            if(answer.length < (totalAttempts.length * questionWords.length)) {

                // Check if guessed number is in right answer or not place or wrong place is number is exists in answer
                var guessedWordExists = questionWords.includes(guessedWord);
                guessedWordIndex = (answer.length % questionWords.length);
            
                // Check if it's in right place or wrong place is number is exists in answer
                if(guessedWordExists) {
                    // Right place if both index are same
                    if(questionWords.indexOf(guessedWord, 0) === guessedWordIndex) {
                        rightPlace = true;
                    }

                    // Wrong place if both index are different
                    else if(questionWords.indexOf(guessedWord, 0) !== guessedWordIndex) {
                        wrongPlace = true;
                    }
                }

                answer.push({guessedWord: guessedWord, rightPlace: rightPlace, wrongPlace: wrongPlace});
                guessedAlphabets.push(guessedWord);

                // Remove answer if already exists
                if(alreadyExistsIndex >= 0) {
                    userAnswers.splice(alreadyExistsIndex, 1);
                }

                // Add news attempt to question
                userAnswers.push({question: questionID, answer: answer, guessed: guessedAlphabets, timeTaken: taskTime});

                this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
            } else {
                swal("Attempts completed", "You have used maximum number of attempts provided for this question");
            }
        } else {
            swal("Already guessed", "You have already guessed the currect word");
        }

        this.forceUpdate();
        // console.log("User answers", userAnswers);
    }

    renderDisplayAlphabets = (question, alphabets, questionWords, savedAnswer, totalAttempts) => {
        var notExistedAlphabets = alphabets;
        var showAplhabhets = this.state.vowels;

        if(questionWords.length > 0) {

            // Push question words to show alphabets
            showAplhabhets = showAplhabhets.concat(questionWords);

            // Get alphabets which are not present already in show alphabets
            notExistedAlphabets = notExistedAlphabets.filter((aplha) => {
                return showAplhabhets.includes(aplha) === false;
            });

            // Show limited random alphabets based on question length 
            if(questionWords.length === 3) {
                notExistedAlphabets.length = 8;
            } else if (questionWords.length === 4) {
                notExistedAlphabets.length = 12;
            }

            // Push random alphabets to show alphabets
            showAplhabhets = showAplhabhets.concat(notExistedAlphabets);

            // Filter show alphabets to remove duplicates
            showAplhabhets = showAplhabhets.filter((alpha, index) => {
                return showAplhabhets.indexOf(alpha) === index;
            });

            // Sort the alphabets to show alphabet wise order
            showAplhabhets.sort();
            // console.log('Show Aplhabhets', showAplhabhets);
        }

        return (
            <div className="col py-4 px-5">
                {showAplhabhets.map((alpha, index) => (
                    this.renderAlphabet(alpha, index, question, questionWords, savedAnswer, totalAttempts)
                ))}
            </div>
        )
    }

    renderAlphabet = (alpha, index, question, questionWords, savedAnswer, totalAttempts) => {
        var lastSaved = {};

        // Convet to array if it is stringified
        if(Array.isArray(savedAnswer) === false) {
            savedAnswer = JSON.parse(savedAnswer);
        }

        // Remove alphabets which are selected and not present in word
        if(savedAnswer && savedAnswer.length > 0) {
            savedAnswer = savedAnswer.filter((answer, index) => {
                return answer.guessedWord === alpha;
            });
        }

        // Get last saved answer from saved answers
        if(savedAnswer.length > 0) {
            lastSaved = savedAnswer[savedAnswer.length - 1];
        }

        if(savedAnswer.length === 0 || (savedAnswer.length > 0 && questionWords.includes(lastSaved.guessedWord))) {
            return <button key={index} className="btn btn-primary btn-xs m-2" onClick={() => this.handleQuestionAnswer(question.id, questionWords, alpha, totalAttempts)}><b>{alpha}</b></button>
        } else {
            return <span key={index} />
        }
    }

    renderAttemptsWords = (totalAttempts, savedAnswer, questionWords) => {
        return (
            <div>
                {totalAttempts.map((attempt, atteIndex) => (
                    <div key={atteIndex} style={{height: 55}}>
                        {questionWords.map((answer, index) => (
                            this.renderAttempt(atteIndex, questionWords, savedAnswer, index)
                        ))}
                    </div>
                ))}
            </div>
        )
    }

    renderAttempt = (atteIndex, questionWords, savedAnswer, index) => {
        const attemptIndex = ((atteIndex * questionWords.length) + (index));
        const attemptAnswer = savedAnswer[attemptIndex];
        const wordBackColor = attemptAnswer && (attemptAnswer.rightPlace ? '#05ea1f' : attemptAnswer.wrongPlace ? 'yellow' : 'grey');
        const wordColor = attemptAnswer && (attemptAnswer.rightPlace ? 'black' : attemptAnswer.wrongPlace ? 'black' : 'white');

        if(savedAnswer.length > attemptIndex) {
            return <span key={index} style={{height: 60, width: 60, border: '1px solid black', padding: '15px 25px', backgroundColor: wordBackColor, color: wordColor}}><b>{attemptAnswer.guessedWord}</b></span>
        } else {
            return <span key={index} style={{height: 60, width: 60, border: '1px solid black', padding: '15px 30px'}}><b>{' '}</b></span>
        }
    }

    renderTestView = () => {
        const {showQuestion, questions, userAnswers} = this.props;
        const {alphabets} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var savedAnswer = [];
        var questionWords = [];
        var totalAttempts = [1,2,3,4,5,6,7];

        // Get saved answer
        if(alreadyExistsIndex >= 0) {
            savedAnswer = userAnswers[alreadyExistsIndex].answer;
        }

        // Convet to array if it is stringified
        if(Array.isArray(savedAnswer) === false) {
            savedAnswer = JSON.parse(savedAnswer);
        }

        if(question.taskDesc !== '') {
            questionWords = question.taskDesc.split('||');
        }

        // Adjust attempts for 3 and 4 letter word
        if(questionWords.length === 3) {
            totalAttempts.length = 5;
        } else if(questionWords.length === 4) {
            totalAttempts.length = 7;
        }

        return (
            <div>
                <h5 className="questions font-weight-bold">{'Guess the ' + questionWords.length + ' letter hidden word'}</h5>
                <h6 className="questions font-weight-bold">{'You have maximum of ' + (questionWords.length === 3 ? '5' : '7') + ' attempts to guess the right word'}</h6>

                <div className="row mt-5 mb-2">
                    <div className="col">
                        {this.renderAttemptsWords(totalAttempts, savedAnswer, questionWords)}
                    </div>

                   {this.renderDisplayAlphabets(question, alphabets, questionWords, savedAnswer, totalAttempts)}
                </div>
                <br/>

                {/* Show next or submit button based on question */}
                {(questions.length - 1) !== showQuestion ? 
                    <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                : 
                    <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                }
            </div>
        )
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default SecretWord;
