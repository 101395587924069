import React from 'react';
import {Button} from '@material-ui/core';
import swal from 'sweetalert';
import {Server, ApiPaths} from '../../../../utils/Server';
import AudioPlayer from '../../../common/player/AudioPlayer';
import { STATE_KEYS } from '../../../../assets/constants';
import Images from '../../../../assets/images/';

class HandWriting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isLoading: false, playAudio: [false, false, false, false, false], disabledAudio: [false, false, false, false, false], audioEnded: [false, false, false, false, false]};
    }

    // Upload image
    handleImageUpload = (e, question, imgNumber) => {
        var formData = new FormData();
        formData.append('filename', e.target.files[0].name);
        formData.append('file', e.target.files[0]);

        const apiheaders = {
            'Content-Type': 'multipart/form-data',
        };

        // Upload file to server
        this.setState({isLoading: true}, async () => {
            await Server.post(ApiPaths.UPLOAD_FILE, formData, {
                headers: apiheaders
              })
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.handleQuestionAnswer(question, data.data, imgNumber); // Add uploaded file link
                    } else {
                        alert('File not uploaded, Please try again');
                    }
                    // console.log('Api response', response.data.data);
                })
                .catch(err => {
                    alert('File not uploaded, Please try again');
                });

                this.setState({isLoading: false});
        });
    }

    handleQuestionAnswer = (question, answer, imgNumber) => {
        var {userAnswers, taskTime} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);
        var savedAnswer = ["", ""];

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0) {
            savedAnswer = userAnswers[alreadyExistsIndex].answer;
            userAnswers.splice(alreadyExistsIndex, 1);
        }

        // Push image url to answer
        savedAnswer[imgNumber] = answer;

        // Push answer to saved answers
        userAnswers.push({question: question, answer: savedAnswer, timeTaken: taskTime});

        // Add new answer for question
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);      

        // console.log("User answers", userAnswers);
    }

    // Remove image
    handleRemoveImage = (question, imgNumber) => {
        swal({
            title: "Are you sure?",
            text: "To remove this image?",
            buttons: ["Cancel", "Remove"],
        })
        .then((willRemove) => {
            if (willRemove) {
                this.handleQuestionAnswer(question, "", imgNumber);
            }
        });
    }

    handleAudioPlay = (showQuestion) => {
        var {playAudio, disabledAudio} = this.state;
        playAudio[showQuestion] = true;
        disabledAudio[showQuestion] = true;
        this.setState({playAudio: playAudio, disabledAudio: disabledAudio});
    }

    handleAudioEnded = (showQuestion) => {
        var {audioEnded, playAudio} = this.state;

        swal({title: "Audio ended", text: "Take a picture of your handwriting and upload using the file upload options here, your time starts now...", button: "START"})
        .then((value) => {
            audioEnded[showQuestion] = true;
            playAudio[showQuestion] = false;
            this.setState({audioEnded: audioEnded, playAudio: playAudio});
        });
    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions} = this.props;
        const {playAudio, disabledAudio, audioEnded, isLoading} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = ["", ""];
        var imageNumbers = [0, 1];

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        return (
            <div className="mb-4 pb-2">
                <div className="row">
                    <div className="col">
                        <div className="text-left">
                            <p className="questions text-center mb-4">
                                <b>Listen to the audio carefully and write it in a paper as you don't have any option to play this audio again, Once the audio ends you will have three minutes to upload your paper. <br/> (Click on play button to listen to audio)</b>
                            </p>

                            <AudioPlayer type={1} playing={playAudio[showQuestion]} disabled={disabledAudio[showQuestion]} url={question.taskDesc} onPlay={() => this.handleAudioPlay(showQuestion)} onEnded={() => this.handleAudioEnded(showQuestion)} />
                        </div>
                    </div>
                </div>
                <hr className="my-4"/>

                <div className="row">
                    <div className="col">
                        {/* Image upload loader */}
                        {isLoading ? 
                            <div className="row">
                                <div className="col" />
                                <div className="col-md-6 col-12">
                                    <img src={Images.loader} height={44} alt="Loading . . ."/><br/>
                                    <button type="button" className="mt-3 btn btn-primary btn-sm">{"Uploading Image . . ."}</button>
                                </div>
                                <div className="col" />
                            </div>
                        :
                            <div className="row my-3">
                                {imageNumbers.map((imgNumber) => (
                                    <div key={imgNumber} className="col p-3">
                                        <div className="card p-3">
                                            <div className="form-group">
                                                {(answerSaved && answerSaved[imgNumber] === "") && (
                                                    <div>
                                                        <label className="small_text"><b>{imgNumber === 0 ? 'Upload first image (Mandatory)' : 'Upload second image (Optional)'} </b></label>
                                                        <input type="file" disabled={!audioEnded[showQuestion]} onChange={(e) => this.handleImageUpload(e, question.id, imgNumber)} className="form-control" />
                                                    </div>
                                                )}

                                                {/* Show after image upload */}
                                                {(answerSaved && answerSaved.length > imgNumber && answerSaved[imgNumber] !== "") &&
                                                    <div>
                                                        <div className="row">
                                                            <div className="col text-left">
                                                                <h6>
                                                                    <i className="fa fa-check-circle text-success mr-1" style={{fontSize:20}}></i>
                                                                    {'Image Uploaded'}
                                                                </h6>
                                                            </div>
                                                            <div className="col text-right">
                                                                <button className="btn btn-sm btn-danger" onClick={(e) => this.handleRemoveImage(question.id, imgNumber)}>{'Remove Image'}</button>
                                                            </div>
                                                        </div>

                                                        {/* Show uploaded image preview */}
                                                        <img src={answerSaved[imgNumber]} className="previewImage img-thumbnail mt-3" alt=""/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>    
                                ))}
                            </div>
                        }

                        {/* Show next or submit button based on question */}
                        <div className="align-center mt-4">
                            {(questions.length - 1) !== showQuestion ? 
                                <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                            : 
                                <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default HandWriting;