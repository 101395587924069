import {GET_TASK_GROUPS, GET_TASK_DETAILS, GET_TASK_QUESTIONS, GET_PLANS_SUCCESS} from '../actions/Tasks';

const questionsData = [
    // {
    //     catId: 31,
    //     createdAt: null,
    //     createdBy: null,
    //     enabled: true,
    //     id: 281,
    //     status: 1,
    //     taskAnswer1: null,
    //     taskAnswer2: null,
    //     taskCatUid: null,
    //     taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/cognizant/Courtroom.jpg",
    //     taskGroupId: 0,
    //     taskOption1: "1. What is the fox feeling in the picture?",
    //     taskOption2: "2. What do you think the judge lion is trying to tell?",
    //     taskOption3: "3. Which character do you understand closely and why?",
    //     taskOption4: "",
    //     taskOption5: "",
    //     taskOption6: "",
    //     taskTime: 0,
    //     taskType: 0,
    //     task_weight: null,
    //     uid: "33dj6399-5979-11eb-a2dc-0a9761169b34",
    //     updatedAt: null,
    //     updatedBy: null,
    //     userId: 0,
    // },
    // {
    //     catId: 31,
    //     createdAt: null,
    //     createdBy: null,
    //     enabled: true,
    //     id: 282,
    //     status: 1,
    //     taskAnswer1: null,
    //     taskAnswer2: null,
    //     taskCatUid: null,
    //     taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/cognizant/Classroom.jpg",
    //     taskGroupId: 0,
    //     taskOption1: "1. What do you think the student standing is feeling?",
    //     taskOption2: "2. According to you what is the teacher telling the students?",
    //     taskOption3: "3. Who among the characters in the picture do you associate yourself with and why?",
    //     taskOption4: "",
    //     taskOption5: "",
    //     taskOption6: "",
    //     taskTime: 0,
    //     taskType: 0,
    //     task_weight: null,
    //     uid: "33dj6399-5979-11eb-a2dc-0a9761169b34",
    //     updatedAt: null,
    //     updatedBy: null,
    //     userId: 0,
    // },
    {
        catId: 20,
        createdAt: null,
        createdBy: null,
        enabled: false,
        id: 193,
        status: 1,
        taskAnswer1: null,
        taskAnswer2: null,
        taskCatUid: null,
        // taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/handwriting/Handwriting+audio.m4a",
        taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/Fixit_10.png",
        taskGroupId: 0,
        // taskOption1: null,
        taskOption1: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/01.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/02.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/03.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/04.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/05.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/06.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/07.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/08.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/09.png",
        taskOption2: null,
        taskOption3: null,
        taskOption4: null,
        taskOption5: null,
        taskOption6: null,
        taskTime: 0,
        taskType: 0,
        task_weight: null,
        uid: "3384gf90-5980-11eb-a2dc-0a9761169b34",
        updatedAt: null,
        updatedBy: null,
        userId: 0,
    },
    // {
    //     "id":228,
    //     "createdAt":null,
    //     "updatedAt":null,
    //     "createdBy":null,
    //     "updatedBy":null,
    //     "uid":"3364fg90-5979-11eb-a2dc-0a9761169b34",
    //     "catId":32,
    //     "taskCatUid":null,
    //     "taskType":0,
    //     taskDesc: "B4||D4||A4||E4",
    //     taskOption1: null,

    //     // Tempo change
    //     // taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/tempochange/D+Q.mp3",
    //     // taskOption1: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/tempochange/D+A.mp3",
    //     taskOption2: null,
    //     taskOption3: null,
    //     taskOption4: null,

    //     // taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/008/Whats_Next_08.PNG",
    //     // taskOption1: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/009/Answer01.PNG||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/009/Answer02.PNG||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/009/Answer03.PNG||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/009/Answer04.PNG",
    //     // taskOption2: "0",
    //     // taskOption3: "0",
    //     // taskOption4: "",
    //     "taskOption5":null,
    //     "taskOption6":null,
    //     "task_weight":null,
    //     "taskAnswer1":null,
    //     "taskAnswer2":null,
    //     "taskTime":0,
    //     "status":1,
    //     "enabled":true
    // },
    // {
    //     "id":3,
    //     "createdAt":null,
    //     "updatedAt":null,
    //     "createdBy":null,
    //     "updatedBy":null,
    //     "uid":"3364fg90-5979-11eb-a2dc-0a9761169b34",
    //     "catId":26,
    //     "taskCatUid":null,
    //     "taskType":0,

    //     // Tempo change
    //     taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/tempochange/I+Q.m4a",
    //     taskOption1:  "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/tempochange/I+A.mp3",
    //     taskOption2: null,
    //     taskOption3: null,
    //     taskOption4: null,

    //     // taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Whats_Next_02.svg",
    //     // taskOption1: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Forward01.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Backward01.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Forward02.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Backward02.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Forward03.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Backward03.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Forward04.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Backward04.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/002/Forward05.svg",
    //     // taskOption2: "2",
    //     // taskOption3: "5",
    //     // taskOption4: "#1acc11",
    //     "taskOption5":null,
    //     "taskOption6":null,
    //     "task_weight":null,
    //     "taskAnswer1":null,
    //     "taskAnswer2":null,
    //     "taskTime":0,
    //     "status":1,
    //     "enabled":true
    // },
    // {
    //     "id":1,
    //     "createdAt":null,
    //     "updatedAt":null,
    //     "createdBy":null,
    //     "updatedBy":null,
    //     "uid":"3364fg90-5979-11eb-a2dc-0a9761169b34",
    //     "catId":26,
    //     "taskCatUid":null,
    //     "taskType":0,
    //     // "taskDesc":"G||O||D",
    //     // "taskOption1":null,
    //     taskDesc: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/003/Whats_Next_03.svg",
    //     // taskOption1: require('../../assets/images/what-next/Circle.svg') + '||' + require('../../assets/images/what-next/Diamond.svg') + '||' + require('../../assets/images/what-next/EqualsTo.svg') + '||' + require('../../assets/images/what-next/Plus.svg') + '||' + require('../../assets/images/what-next/Square.svg') + '||' + require('../../assets/images/what-next/Triangle.svg'),
    //     // taskOption1: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/01.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/02.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/03.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/04.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/05.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/06.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/07.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/08.png||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/fixit/fixit01/09.png",
    //     taskOption1: "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/003/Circle.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/003/Diamond.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/003/EqualsTo.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/003/Square.svg||https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/whatsnext/003/Triangle.svg",
    //     taskOption2: "3",
    //     taskOption3: "3",
    //     taskOption4: "#ff333d",
    //     "taskOption5":null,
    //     "taskOption6":null,
    //     "task_weight":null,
    //     "taskAnswer1":null,
    //     "taskAnswer2":null,
    //     "taskTime":0,
    //     "status":1,
    //     "enabled":true
    // },
    
   
    // {
    //     id: 1,
        // taskDesc: 'clue:The person who owns a Benz doesnt like apple.||clue: The person who likes Guava is sitting to the immediate right of the person who likes Grapes.||clue:The person who likes apple owns a Swift. ||clue:C is not sitting to the immediate right of A, who owns an Audi||clue:D owns an Etios and is sitting opposite to the person who likes Banana. ||clue:B likes grapes and is sitting opposite to the person who owns a Swift.||clue:The person who likes Banana owns an Audi ||Header: A||Header: B||Header: C||Header: D',
        // taskOption1: 'O: Banana||Grapes ||Apple || Guava',
        // taskOption2: 'O: Audi||Benz||Swift||Etios',
        // taskOption3: '',
        // taskOption4: '',
        // taskOption5: '',
        // taskOption6: '',
        // taskDesc: require('../../assets/images/what-next/WhatsNext.jpg'),
        // taskOption1: require('../../assets/images/what-next/Circle.svg') + '||' + require('../../assets/images/what-next/Diamond.svg') + '||' + require('../../assets/images/what-next/EqualsTo.svg') + '||' + require('../../assets/images/what-next/Plus.svg') + '||' + require('../../assets/images/what-next/Square.svg') + '||' + require('../../assets/images/what-next/Triangle.svg'),
        // taskOption2: '',
        // taskOption3: '',
        // taskOption4: '',
        // taskOption5: '',
        // taskOption6: '',
        // taskType: 0,
    // },
    // {
    //     id: 2,
        // taskDesc: 'clue:The biggest resort is somewhere between the Goa resort and the resort that has 15 staff, in that order ||clue:In the second position is the resort founded in the 20s||clue:The White resort is somewhere to the left of the smallest resort||clue:In the middle is the resort owned by the German.||clue:The resort owned by the American is somewhere between the resort that has 25 staff and the White resort, in that order.||clue:Bharat manages the Red resort.||clue:The Delhi resort has 20 staff.||clue:The Punjab resort is somewhere to the right of the yellow resort.||clue:Ram built his resort in the 40s.||clue:The resort that has 25 staff is exactly to the left of the resort managed by Bharat.||clue:At the first position is the resort whose owner was born in Paris.||clue:The yellow resort is somewhere to the left of the resort that has 20 staff.||clue:Ram works at the first resort.||clue:The Mumbai resort is somewhere between the resort owned by the French and the Bangalore resort, in that order.||clue:Ram owns the fourth resort.||clue:The Mumbai resort is White.||clue:Peter manages the fourth resort.||clue:The yellow resort is somewhere to the left of the resort founded right after 1930 ||clue:The White resort is next to the resort founded in the 20s.||clue:The resort managed by Peter is somewhere between the red resort and the resort managed by Akbar, in that order.||clue:At the third position is the resort founded in 1920 ||Header: Resort 1||Header: Resort 2||Header: Resort 3||Header: Resort 4||Header: Resort 5',
        // taskOption1: 'Color:Blue||Red||White||Green||Purple',
        // taskOption2: 'Place:Goa||Delhi||Mumbai||Bangalore||Punjab',
        // taskOption3: 'Owner:French||American||German||Italian||British',
        // taskOption4: 'Cook:Ram||Bharat||Sachin||Peter||Akbar',
        // taskOption5: 'Staff:25||30||15||10||20',
        // taskOption6: 'Founded:1910s||1930s||1920s||1950s||1940s',
        // taskDesc: '4||5||6||3',
        // taskOption1: '',
        // taskOption2: '',
        // taskOption3: '',
        // taskOption4: '',
        // taskOption5: '',
        // taskOption6: '',
        // taskType: 0,
    // }
];

const initialState = {
    day1_tasks: [],
    day2_tasks: [],
    taskData: {},
    task_questions: [],
    subscription_plans: [],
}

const Task_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_TASK_GROUPS :
            return Object.assign({}, state, { day1_tasks : payload.groups.day1, day2_tasks: payload.groups.day2 });

        case GET_TASK_DETAILS :
            return Object.assign({}, state, { taskData : payload.taskData });
        
        case GET_TASK_QUESTIONS :
            return Object.assign({}, state, { task_questions : payload.questions });

        case GET_PLANS_SUCCESS :
            return Object.assign({}, state, {subscription_plans: payload.plans});

        default :  
            return state;
    }
}

export default Task_reducer;