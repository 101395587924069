import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class Fixit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedImage: 0};
    }

    componentDidMount() {
        this.handleStartElementDrag();
    }

    componentDidUpdate(oldProps, newProps) {
        // Handle drag elements when question number changes
        if(oldProps.showQuestion !== this.props.showQuestion) {
            this.handleStartElementDrag();
        }

        // console.log(oldProps.showQuestion, this.props.showQuestion);
    }

    handleStartElementDrag = () => {
        const {showQuestion, questions} = this.props;
        var question = questions[showQuestion];
        var subQuestions = [];

        if(question.taskOption1 && question.taskOption1 !== '') {
            subQuestions = question.taskOption1.split('||');

            if(subQuestions.length > 0) {
                subQuestions.map((subQuestion, index) => (
                    this.handleDragElement(document.getElementById(("draggable" + showQuestion + "View" + index)), index)
                ))
            }
        }
    }

    handleQuestionAnswer = (subQuestion, answer) => {
        const {showQuestion, userAnswers, questions, taskTime} = this.props;
        var question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answers = [{top: '0px', left: '0px', transform: 'rotate(0deg)'}, {top: '0px', left: '150px', transform: 'rotate(0deg)'}, {top: '0px', left: '300px', transform: 'rotate(0deg)'}, {top: '150px', left: '0px', transform: 'rotate(0deg)'}, {top: '150px', left: '150px', transform: 'rotate(0deg)'}, {top: '150px', left: '300px', transform: 'rotate(0deg)'}, {top: '300px', left: '0px', transform: 'rotate(0deg)'}, {top: '300px', left: '150px', transform: 'rotate(0deg)'}, {top: '300px', left: '300px', transform: 'rotate(0deg)'}, {top: '450px', left: '0px', transform: 'rotate(0deg)'}, {top: '450px', left: '150px', transform: 'rotate(0deg)'}, {top: '450px', left: '300px', transform: 'rotate(0deg)'}]; // Set default image positions
        var subQuestions = [];

        // console.log('Answers', answers);

        // Split for sub questions
        if(question.taskOption1 !== '') {
            subQuestions = question.taskOption1.split('||');

            // Restrict answers to subquestions
            if(subQuestions.length > 0) {
                answers.length = subQuestions.length;
            }
        }
       
        // Update answer if alreay exists
        if(alreadyExistsIndex >= 0) {
            answers = userAnswers[alreadyExistsIndex].answer; // Update already saved answers
            userAnswers.splice(alreadyExistsIndex, 1); // Remove already saved answer
            // console.log('Saved answer', answers);
        }

        // Update answer
        answers[subQuestion] = answer;
        // console.log('New answer', answers);

        // Push answers
        userAnswers.push({question: question.id, answer: answers, timeTaken: taskTime});

        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
        
        // console.log("User answers", userAnswers)
    }

    // Drag element
    handleDragElement = (elmnt, index) => {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        var subQuestion = index;
        var answer = {top: 0, left: 0, transform: 'rotate(0deg)'};

        const dragMouseDown = (e) => {
            e = e || window.event;
            e.preventDefault();
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
        }

        if (document.getElementById(elmnt.id + "Image")) {
            /* if present, the header is where you move the DIV from:*/
            document.getElementById(elmnt.id + "Image").onmousedown = dragMouseDown;
        } else {
            /* otherwise, move the DIV from anywhere inside the DIV:*/
            elmnt.onmousedown = dragMouseDown;
        }

        const elementDrag = (e) => {
            e = e || window.event;
            e.preventDefault();
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        const closeDragElement = (e) => {
            e = e || window.event;
            e.preventDefault();
            
            // Get image element
            var imgElemnt = document.getElementById(elmnt.id + "Image");

            // Store positions in user answers
            answer = {top: elmnt.style.top, left: elmnt.style.left, transform: 'rotate(' + this.handleGetElementRotation(elmnt)  + 'deg)', height: elmnt.offsetHeight + 'px', width: elmnt.offsetWidth + 'px', imgHeight: imgElemnt.offsetHeight + 'px', imgWidth: imgElemnt.offsetWidth + 'px' };
            this.handleQuestionAnswer(subQuestion, answer);

            // console.log('Answer', answer);

            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    // Find rotation
    handleGetElementRotation = (elmnt) => {
        var st = window.getComputedStyle(elmnt, null);
        var tr = st.getPropertyValue("-webkit-transform") ||
                st.getPropertyValue("-moz-transform") ||
                st.getPropertyValue("-ms-transform") ||
                st.getPropertyValue("-o-transform") ||
                st.getPropertyValue("transform") ||
                "fail...";
        
        if( tr !== "none") {            
            var values = tr.split('(')[1];
                values = values.split(')')[0];
                values = values.split(',');
            var a = values[0];
            var b = values[1];
            var c = values[2];
            var d = values[3];
        
            var scale = Math.sqrt(a*a + b*b);

            var radians = Math.atan2(b, a);
            if ( radians < 0 ) {
                radians += (2 * Math.PI);
            }

            var angle = Math.round( radians * (180/Math.PI));
        
        } else {
            var angle = 0;
        }
        
        return angle;
    }

    handleImageRotation = (type) => {
        // type 1 for clockwise and 2 for vice versa
        const {selectedImage} = this.state;
        const {showQuestion} = this.props;

        var elmnt = document.getElementById(("draggable" + showQuestion + "View" + selectedImage));
        var currentRotation = this.handleGetElementRotation(elmnt);
        var newRotation = type === 1 ? (currentRotation - 45) : (currentRotation + 45); // Calculate new rotation
        var answer = {top: elmnt.style.top, left: elmnt.style.left, transform: 'rotate(' + newRotation + 'deg)'};
        this.handleQuestionAnswer(selectedImage, answer);

    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions, error} = this.props;
        var question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = [{top: '0px', left: '0px', transform: 'rotate(0deg)'}, {top: '0px', left: '150px', transform: 'rotate(0deg)'}, {top: '0px', left: '300px', transform: 'rotate(0deg)'}, {top: '150px', left: '0px', transform: 'rotate(0deg)'}, {top: '150px', left: '150px', transform: 'rotate(0deg)'}, {top: '150px', left: '300px', transform: 'rotate(0deg)'}, {top: '300px', left: '0px', transform: 'rotate(0deg)'}, {top: '300px', left: '150px', transform: 'rotate(0deg)'}, {top: '300px', left: '300px', transform: 'rotate(0deg)'}, {top: '450px', left: '0px', transform: 'rotate(0deg)'}, {top: '450px', left: '150px', transform: 'rotate(0deg)'}, {top: '450px', left: '300px', transform: 'rotate(0deg)'}]; // Set default image positions
        var subQuestions = [];
        
        // console.log('Question answer index', alreadyExistsIndex);

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
            // console.log("Answer saved", answerSaved);
        }

        // Convet to array if it is stringified
        if(Array.isArray(answerSaved) === false) {
            answerSaved = JSON.parse(answerSaved);
        }

        if(question.taskOption1 !== '') {
            subQuestions = question.taskOption1.split('||');

            // Restrict answers to subquestions
            if(subQuestions.length > 0) {
                answerSaved.length = subQuestions.length;
            }
        }
    
        return (
            <div>
                <h5 className="font-weight-bold mt-2">
                    Drag and drop the given options to make next image
                </h5>

                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-5 col-5 mb-5">
                        <img src={question.taskDesc} className="img-fluid" alt="" />
                    </div>
                    <div className="col"></div>
                </div>
                <hr/>

                <div className="row">
                    <div className="col p-2">
                        <h6 className="font-weight-bold">Drag these small images to create a complete full image</h6>
                        <div className="row" style={{position: 'relative'}}>
                            {subQuestions.map((subQuestion, index) => (
                                <div key={index} id={"draggable" + showQuestion + "View" + index} className="col-md-4 col-4 mb-4 draggableView" style={answerSaved[index]}>
                                    <img src={subQuestion} id={"draggable" + showQuestion + "View" + index + "Image"} draggable className="draggable img-fluid" alt="" onClick={() => this.setState({selectedImage: index})} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col p-4" style={{border: '1px solid lightGrey'}}>
                        <h6 className="font-weight-bold">Drag given images and form new images here</h6>
                        <div className="row fixitImageDropContainer">
                            <div className="fixitImageDropView">
                                {/*  */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="mb-2">
                            <Button variant="contained" color="primary" onClick={() => this.handleImageRotation(1)}> <i className="fa fa-repeat"></i> </Button>
                            <Button variant="contained" className="ml-4" color="primary" onClick={() => this.handleImageRotation(2)}> <i className="fa fa-undo"></i> </Button>
                        </div>
                        <span className="small_text">Click on any image and use these options to rotate the selected image</span>
                    </div>
                    <div className="col" />
                </div>
                <hr/>

                <div className="text-center">
                    <span className="errorMessage">{error}</span>
                </div>
                
                {/* Show next or submit button based on question */}
                {(questions.length - 1) !== showQuestion ? 
                    <Button variant="contained" className="float-right" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                : 
                    <Button variant="contained" className="float-right" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                }
            </div>
        )
    }
      
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default Fixit;