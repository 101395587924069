import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import '../../App.css';
import '../../Responsive.css';
import { connect } from 'react-redux';
import {userRegister, displayError} from '../../redux/actions/Home';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {firstName: 'Satish', lastName: 'M P', mobile: '9743381008', email: 'satishmp1008@gmail.com', password: 'satishmp12', confirmPassword: 'satishmp12', agreeTerms: false};
    this.state = {firstName: '', lastName: '', mobile: '', email: '', password: '', confirmPassword: '', agreeTerms: false};
  }

  handleRegister = () => {
    const {firstName, lastName, mobile, email, password, confirmPassword, agreeTerms} = this.state;

    var emailRegExp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        // var mobileReg = new RegExp('/^[0-9]{10}$/');

    if(firstName === '' || lastName === '') {
      this.props.onDisplayError('Please Provide first and last name');
    } else if(mobile.length !== 10){
      this.props.onDisplayError('Invalid mobile number');
    } else if(!emailRegExp.test(email)){
      this.props.onDisplayError('Invalid Email ID');
    } else if(password.length < 8) {
      this.props.onDisplayError('Password must have atleast 8 characters');
    } else if(password !== confirmPassword) {
      this.props.onDisplayError("Passwords didn't matched");
    } else if(!agreeTerms) {
      this.props.onDisplayError("Please agree terms and conditions to continue");
    } else {
      this.props.onDisplayError('');

      var formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('mobile', mobile);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('confirmPassword', confirmPassword);
      formData.append('createdBy', 'WebApp');

      this.props.onUserRegister(formData);
    }
  }
  render() {
    const {error} = this.props;
    const {firstName, lastName, mobile, email, password, confirmPassword, agreeTerms} = this.state;

    return (
      <Container component="main" className="BgImage" maxWidth="lg" xs={12} lg={4} md={4}>
        <CssBaseline />
        <Container className="paper" component="main" maxWidth="xs" xs={12} lg={4} md={4}>
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className="form" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={firstName}
                  onChange={(e) => this.setState({firstName: e.target.value})}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  placeholder="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={lastName}
                  onChange={(e) => this.setState({lastName: e.target.value})}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  placeholder="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={email}
                  onChange={(e) => this.setState({email: e.target.value})}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  placeholder="Email ID"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={mobile}
                  onChange={(e) =>  this.setState({mobile: e.target.value})}
                  variant="outlined"
                  required
                  fullWidth
                  id="mobile"
                  placeholder="Mobile Number"
                  name="mobile"
                  autoComplete="mobile"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onChange={(e) => this.setState({password: e.target.value})}
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="password"
                  placeholder="Password"
                  name="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={confirmPassword}
                  onChange={(e) => this.setState({confirmPassword: e.target.value})}
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  id="confirmPassword"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" onChange={() => this.setState({agreeTerms: !agreeTerms})} />}
                  label="Agree to terms and conditions."
                />
              </Grid>
            </Grid>

            {error && error !== '' ? <p className="errorMessage">{error}</p> : null}

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              onClick={() => this.handleRegister()}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-start">
              <Grid item>
                <Link to="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </Container>
        <Box mt={5} ml={50} >
          {/* <Copyright /> */}
        </Box>
      </Container>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    is_auth: state.home.is_auth,
    auth_user: state.home.auth_user,
    error: state.home.error,
  }
}

const mapActionsToProps = {
  onUserRegister: userRegister,
  onDisplayError: displayError,
}

export default connect(mapStatesToProps, mapActionsToProps) (SignUp);
