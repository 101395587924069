import React, {useCallback, useState, useRef} from "react";
import Webcam from "react-webcam";

const WebCamScreen = () => {
    const webcamRef = useRef(null);
    const [imgPath, setImagePath] = useState('');
  
    const capture = useCallback(
        () => {
            setImagePath(webcamRef.current.getScreenshot());
        },
        [webcamRef]
    );
  
    return (
        <div className="container">
            <h5 className="my-3">{'Click on capture button to capture a image and click on save to save in your system'}</h5>
            <div className="row">
                <div className="col">
                    <Webcam
                        audio={false}
                        height={400}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={'100%'}
                    />
                    <button type="button" className="btn btn-primary p-3" onClick={capture}>{'Capture photo'}</button>
                </div>
                <div className="col">
                    {imgPath !== '' && (
                        
                        <div className="mt-2">
                            <img src={imgPath} className="img-fluid" alt="" />
                            <a download href={imgPath} className="btn btn-primary p-3 mt-3" target="_blank" rel="noopener noreferrer">{'Save photo'}</a>
                            <br/>
                            <span className="small_text">{'Photos will be saved do downloads folder in your system,\n You can upload it from there'}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WebCamScreen;
