import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class NameAndNarrate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {error: ''};
    }

    handleQuestionAnswer = (question) => {
        var titleValue = document.getElementById('answer_title_' + question).value;
        var descriptionValue = document.getElementById('answer_description_' + question).value;

        var regExp = new RegExp(/^[a-zA-Z ]*$/);

        if(regExp.test(titleValue)) {
            this.setState({error: ''});

            var {userAnswers} = this.props;
            var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

            // Remove answer if alreay exists
            if(alreadyExistsIndex >= 0 || titleValue === '') {
                userAnswers.splice(alreadyExistsIndex, 1);
            }

            // Push new answer
            if(titleValue !== '' || descriptionValue !== '') {
                userAnswers.push({question: question, answer: (titleValue + '||' + descriptionValue)});
            }

            // Add new answer for question
            this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
        } else {
            this.setState({error: 'Only characters are allowed'});
        }

        // console.log("User answers", userAnswers);
    }

    renderTestView = (question, index, showQuestion) => {
        return (
            <div key={index} className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12" onClick={() => {
                this.setState({error: ''});
                this.props.handleSetState(STATE_KEYS.QUESTION, index)}
            }>
                <img src={question.taskDesc} className={(showQuestion === index) ? "nameNarrateImage img-thumbnail bg-primary" : "nameNarrateImage"} alt="" />
            </div>
        )
    }

    render() {
        const {showQuestion, questions, userAnswers, maxCharacters} = this.props;
        const {error} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answersSaved = [];
        var charactersLeft = maxCharacters;

        if(alreadyExistsIndex >= 0) {
            answersSaved = userAnswers[alreadyExistsIndex].answer.split('||');
            charactersLeft = (maxCharacters - answersSaved[1].length);
        }

        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="questions font-weight-bold">Select any image to narrate</h5>
                    {/* <span className="small_text">Note: Your answers will be cleared if you change your selected image later </span> */}
                    <div className="row justify-content-center">
                        {
                            questions.map((question, index) => (
                                this.renderTestView(question, index, showQuestion)
                            ))
                        }
                    </div>
                    <hr/>

                    <div className="row">
                        <div className="col py-5 px-3">
                            <img src={question.taskDesc} className="img-fluid" alt="" />
                        </div>
                        <div className="col form-group">
                            <h5 className="text-centre small_text">Narrate about selected image here</h5>
                            <h6 className="text-left small_text">Title:</h6>
                            <input type="text" className="form-control" placeholder="Title" id={"answer_title_" + question.id} onChange={() => this.handleQuestionAnswer(question.id)} value={answersSaved.length > 0 ? answersSaved[0] : ''} />
                            {(error && error !== '') && <span className="errorMessage">{error}</span>}

                            <br/>
                            <h6 className="text-left small_text">Description:</h6>
                            <textarea rows={12} maxLength={maxCharacters} className="form-control mb-1" id={"answer_description_" + question.id} onChange={() => this.handleQuestionAnswer(question.id)} value={answersSaved.length > 0 ? answersSaved[1] : ''} placeholder="Description" />
                            <span className="float-left leftCharacters">Characters left: {charactersLeft}</span>
                        </div>
                    </div>

                    {/* Show next or submit button based on question */}
                    <div className="align-center my-2">
                        {(userAnswers.length >= 2) && 
                            <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default NameAndNarrate;