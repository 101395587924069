import React from 'react';
import {Button} from '@material-ui/core';
import {Server, ApiPaths} from '../../../../utils/Server';
import swal from 'sweetalert';
import { STATE_KEYS } from '../../../../assets/constants';
import Images from '../../../../assets/images/';

class EnactQuiz extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isLoading: false};
    }

    handleImageUpload = (e, question) => {
        var formData = new FormData();
        formData.append('filename', e.target.files[0].name);
        formData.append('file', e.target.files[0]);

        const apiheaders = {
            'Content-Type': 'multipart/form-data',
        };

        // Upload file to server
        this.setState({isLoading: true}, async () => {
            await Server.post(ApiPaths.UPLOAD_FILE, formData, {
                headers: apiheaders
              })
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.handleQuestionAnswer(question, data.data); // Add uploaded file link
                    } else {
                        alert('File not uploaded, Please try again');
                    }
                    // console.log('Api response', response.data.data);
                })
                .catch(err => {
                    alert('File not uploaded, Please try again');
                });

                this.setState({isLoading: false});
        });
    }

    // Handle answer
    handleQuestionAnswer = (question, answer) => {

        var {userAnswers} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

        //  remove answer if alreay exists
        if(alreadyExistsIndex >= 0 || answer === '') {
            userAnswers.splice(alreadyExistsIndex, 1);
        }

        // Add push amswer
        if(answer !== '') {
            userAnswers.push({question: question, answer: answer});
        }

        //  Add new answer for question
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);      

        // console.log("User answers", userAnswers)
    }

    // Remove image
    handleRemoveImage = (question) => {
        swal({
            title: "Are you sure?",
            text: "To remove this image to upload another one?",
            buttons: ["Cancel", "Remove"],
        })
        .then((willRemove) => {
            if (willRemove) {
                var {userAnswers} = this.props;
                var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);
        
                // remove answer if alreay exists
                if(alreadyExistsIndex >= 0) {
                    userAnswers.splice(alreadyExistsIndex, 1);
                }
        
                // Add new answer for question
                this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
                // console.log("User answers after remove", userAnswers)
            }
        });
    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions} = this.props;
        const {isLoading} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = '';

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="text-left">
                            <img src={question.taskDesc} className="img-fluid" alt="" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        {/* Image upload loader */}
                        {isLoading ? 
                            <div className="form-group">
                                <img src={Images.loader} height={44} alt="Loading . . ."/><br/>
                                <button type="button" className="mt-3 btn btn-primary btn-sm">{"Uploading Image . . ."}</button>
                            </div>
                        :
                            <div className="form-group">
                                {/* Show before image upload */}
                                {(answerSaved === '') && (
                                    <div>
                                        <input type="file" name={"enact_image_" + showQuestion} id={"enact_image_" + showQuestion} onChange={(e) => this.handleImageUpload(e, question.id)} className="form-control" />
                                        <a href={"/web-cam"} className="btn btn-primary py-2 px-3 mt-3" target="_blank" rel="noopener noreferrer">{'Open web cam'}</a>
                                        <br/>
                                        <span className="small_text">{'Note: Webcam will be opened in new tab, capture and save a pic in your system there and later comeback here and choose file to upload.'}</span>
                                    </div>
                                )}
                            
                                {/* Show after image upload */}
                                {(answerSaved && answerSaved !== '') &&
                                    <div>
                                        <div className="row">
                                            <div className="col text-left">
                                                <h6>
                                                    <i className="fa fa-check-circle text-success mr-1" style={{fontSize:20}}></i>
                                                    {'Image Uploaded'}
                                                </h6>
                                            </div>
                                            <div className="col text-right">
                                                <button className="btn btn-sm btn-danger" onClick={(e) => this.handleRemoveImage(question.id)}>{'Remove Image'}</button>
                                            </div>
                                        </div>

                                        {/* Show uploaded image preview */}
                                        <img src={answerSaved} className="previewImage img-thumbnail mt-3" alt="" />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>

                {/* Show next or submit button based on question */}
                <div className="align-center mt-5">
                    {(questions.length - 1) !== showQuestion ? 
                        <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                    : 
                        <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                    }
                </div>
            </div>
        )
    }
      
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="text-center mb-4">Take a picture of yourself as same as in the picture given below and upload it</h5>
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default EnactQuiz;