import React from 'react';
import ReactPlayer from 'react-player';
import {PLAYER_CONFIG} from '../../../assets/constants/';

const AudioPlayer = props => {
    const {playing, disabled, url, type} = props;

    // Type 1 - Play audio only once
    // Type 2 - No limit

    return (
        <div className="col">
            <ReactPlayer
                playing={playing}
                controls={true}
                width={'100%'}
                height={30}
                url={url}
                config={PLAYER_CONFIG}
                style={{pointerEvents: disabled ? 'none' : 'auto'}}
                onPlay={() => (type === 1 ? props.onPlay() : {})}
                onEnded={() => (type === 1 ? props.onEnded() : {})}
            />

            <div
                style={{
                    backgroundColor: 'whitesmoke',
                    position: 'absolute',
                    left: 60,
                    top: 0,
                    height: 30,
                    width: 80,
                    zIndex: 10,
                }}
            />
        </div>
    );
};

export default AudioPlayer;
