import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class MixerQuiz extends React.Component {

    constructor(props) {
        super(props);
        this.state = {error: ''};
    }

    handleQuestionAnswer = (e, question, subQuestion) => {
        e.preventDefault();
        var answer = e.target.value;
        var {userAnswers} = this.props;
        var existAnswers = ['', '', '', '', ''];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);
        var regExp = new RegExp(/^[a-zA-Z]*$/);

        if(regExp.test(answer)) {
            this.setState({error: ''});
            
            // remove answer if alreay exists
            if(alreadyExistsIndex >= 0) {
                existAnswers = userAnswers[alreadyExistsIndex].answer;
                userAnswers.splice(alreadyExistsIndex, 1);
            }

            // Replace the previous answer with new one
            existAnswers[subQuestion] = answer !== '' ? answer.toUpperCase() : "";

            // Add news answer for question
            userAnswers.push({question: question, answer: existAnswers});

            this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
        } else {
            this.setState({error: 'Only characters are allowed'});
        }

        // console.log("User answers", userAnswers)
    }

    renderTestView = () => {
        const {showQuestion, questions, userAnswers} = this.props;
        const {error} = this.state;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questions[showQuestion].id);
        var answerSaved = ['', '', '', '', ''];
        var subQuestions = [];

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        if(questions[showQuestion].taskDesc !== '') {
            subQuestions = questions[showQuestion].taskDesc.split('||');
        }
        
        return (
            <div className="text-left">

                {/* Looping total answers */}
                {subQuestions.map((subQuestion, index) => (
                    (index < (subQuestions.length - 1)) ? (
                        <div key={index} className="row mt-4 mb-2" style={{borderBottom: '1px solid lightgrey'}}>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                <h6>{subQuestion.replace('Q:', '')}</h6>
                            </div>
                            <div className="col">
                                <input className="form-control w-75 mb-2" maxLength={subQuestion.trim().slice((subQuestion.length - 2), (subQuestion.length - 1))} style={{marginTop: '-10px', textTransform: 'uppercase'}} name={"exampleRadios" + subQuestion} onChange={(e) => this.handleQuestionAnswer(e, questions[showQuestion].id, index)} placeholder={"Answer"} value={answerSaved[index]} />
                            </div>
                        </div>
                    )
                    : (
                        <div key={index} className="row mt-4 mb-4" style={{borderBottom: '1px solid lightgrey'}}>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                {showQuestion < 4 ? (<h6><b>{subQuestion.replace('Clue :', '')}</b></h6>) : <h6><b>{"_______________" + subQuestion.replace('Clue :', '').replace(/[_]/g, "")}</b></h6>}
                            </div>
                            <div className="col">
                                <input className="form-control w-75 mb-2" maxLength={(subQuestion.replace('Clue :', '').trim()).length} style={{marginTop: '-10px', textTransform: 'uppercase'}} name={"exampleRadios"} onChange={(e) => this.handleQuestionAnswer(e, questions[showQuestion].id, 3)} placeholder={"Answer"} value={answerSaved[3]} />
                                {(error && error !== '') && <p className="errorMessage my-2">{error}</p>}
                            </div>
                        </div>
                    )
                ))}

                {/* Show next or submit button based on question */}
                {(questions.length - 1) !== showQuestion ? 
                    <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                : 
                    <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                }
            </div>
        )
    }

    render() {
      return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default MixerQuiz;
