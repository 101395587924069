import React from 'react';
import {Button} from '@material-ui/core';
import swal from 'sweetalert';
import { STATE_KEYS } from '../../../../assets/constants';

class MasterMind extends React.Component {

    handleQuestionAnswer = (questionID, questionNumbers) => {        
        var {userAnswers, taskTime} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questionID);
        var answer = [];
        var righPlaceCount = 0;
        var rightPlaceNumbers = [];
        var wrongPlaceNumbers = [];

        // console.log('Right and wrong numbers', rightPlaceNumbers + ' ' + wrongPlaceNumbers);

        var guessedNumber = document.getElementById("guess_input_" + questionID).value;

        // Validate for number
        if(guessedNumber && guessedNumber.length === 4) {
            
            // Clear input value
            document.getElementById("guess_input_" + questionID).value = '';

            
            // Check answer if alreay exists
            if(alreadyExistsIndex >= 0) {
                // Get the saved answer
                answer = userAnswers[alreadyExistsIndex].answer;

                // Check whether user answered correct already
                if(answer && answer.length > 0) {
                    righPlaceCount = answer[0].rightPlace;
                    // console.log('Answer', answer[0]);
                }
            }

            // console.log('Right places', righPlaceCount);

            if(righPlaceCount !== 4) {

                if(answer && answer.length < 20) {
                    if(questionNumbers && questionNumbers.length > 0 && guessedNumber !== '' && guessedNumber.length === questionNumbers.length) {
                        var guessedNumberArray = guessedNumber.split('');

                        for(let i=0; i<guessedNumberArray.length; i++) {

                            // Check if guessed number is in right answer or not place or wrong place is number is exists in answer
                            var guessNumberExists = questionNumbers.includes(guessedNumberArray[i]);

                            // Check if it's in right place or wrong place is number is exists in answer
                            if(guessNumberExists) {
                                // Right place if both index are same
                                if(questionNumbers.indexOf(guessedNumberArray[i], i) === guessedNumberArray.indexOf(guessedNumberArray[i], i)) {
                                    // console.log('Right number', guessedNumberArray[i]);
                                    rightPlaceNumbers.push(guessedNumberArray[i]);
                                }

                                // Wrong place if both index are different
                                if(questionNumbers.indexOf(guessedNumberArray[i], i) !== guessedNumberArray.indexOf(guessedNumberArray[i], i)) {
                                    // console.log('Wrong number', guessedNumberArray[i]);
                                    wrongPlaceNumbers.push(guessedNumberArray[i]);
                                }
                            }
                        }

                        // Adding answer at the beginning
                        answer.unshift({guessedNumber: guessedNumber, rightPlace: rightPlaceNumbers.length, wrongPlace: wrongPlaceNumbers.length});

                        // Remove answer if already exists
                        if(alreadyExistsIndex >= 0) {
                            userAnswers.splice(alreadyExistsIndex, 1);
                        }

                        // Add news attempt to question
                        userAnswers.push({question: questionID, answer: answer, timeTaken: taskTime});

                        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
                        this.props.handleSetState(STATE_KEYS.ERROR, '');

                    } else {
                        this.props.handleSetState(STATE_KEYS.ERROR, 'Must have ' + questionNumbers.length + ' numbers');
                    }
                } else {
                    swal("Attempts completed", "You have used maximum number of attempts provided for this question");
                }
            } else {
                swal("Already guessed correct number", "You already guessed correct number you can't attempt any more from here to this question", {
                    icon: "warning",
                });
            }
        }

        // console.log('Task time', taskTime);
        // console.log("User answers", userAnswers);
    }

    renderTestView = () => {
        const {showQuestion, questions, userAnswers, error} = this.props;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = [];
        var questionNumbers = [];
        var righPlaceCount = 0;

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;

            // Convet to array if it is stringified
            if(Array.isArray(answerSaved) === false) {
                answerSaved = JSON.parse(answerSaved);
            }

            // Check whether user answered correct already
            if(answerSaved && answerSaved.length > 0) {
                righPlaceCount = answerSaved[0].rightPlace;
                // console.log('Answer', answer[0]);
            }
        }

        if(question.taskDesc !== '') {
            questionNumbers = question.taskDesc.split('||');
        }
        
        return (
            <div>
                <h5 className="questions font-weight-bold">{'Guess the hidden number'}</h5>
                <h6 className="questions font-weight-bold">{'You have maximum of 20 attempts to guess the right number'}</h6>

                <div className="row mt-5 mb-2">
                    <div className="col">
                        <div className="row">
                            <div className="col d-inline">
                                <input type="number" id={"guess_input_" + question.id} max={4} disabled={righPlaceCount === 4} maxLength={4} className="form-control w-50 mb-2 float-left" placeholder={"Guess the number"} onChange={() => this.handleQuestionAnswer(question.id, questionNumbers)} />
                                <Button className="float-right mr-4" variant="contained" color="primary" onClick={() => this.handleQuestionAnswer(question.id, questionNumbers)}>GUESS</Button>
                            </div>
                        </div>

                        <div className="text-center">
                            <h6 className="errorMessage">{error}</h6>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                        {(answerSaved && answerSaved.length > 0) && (
                            <table className="table table-bordered text-center">
                                <thead className="bg-blue text-light">
                                    <tr>
                                        <th width="10%">Attempt</th>
                                        <th width="30%">Guessed number</th>
                                        <th width="30%">Right numbers in right place</th>
                                        <th width="30%">Right numbers in wrong place</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {answerSaved.map((answer, index) => (
                                        <tr key={index} className={answer.rightPlace === 4 ? "bg-success text-light" : "bg-danger text-light"}>
                                            <td>{(answerSaved.length) - (index)}</td>
                                            <td>{answer.guessedNumber}</td>
                                            <td>{answer.rightPlace}</td>
                                            <td>{answer.wrongPlace}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <br/>

                {/* Show next or submit button based on question */}
                {(questions.length - 1) !== showQuestion ? 
                    <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                : 
                    <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                }
            </div>
        )
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default MasterMind;
