import React from 'react';
import {connect} from 'react-redux';
import {Button} from '@material-ui/core';
import {mainLoadingTrue, mainLoadingFalse, displayError, displayMessage, setAuthUser} from '../../../redux/actions/Home';
import {getSubscriptionPlans} from '../../../redux/actions/Tasks';
import {Server, ApiPaths} from '../../../utils/Server';
import Images from '../../../assets/images';
import swal from 'sweetalert';

class PaymentScreen extends React.Component {

    componentDidMount() {
        this.handleGetPlans();
    }

    // Get plans
    handleGetPlans = () => {
        this.props.onGetPlans();
    }

    // Checkout
    handleCheckOut = (plan) => {
        var authUser = this.props.auth_user;

        if(plan.plan_type === 1 || plan.plan_type === 3) {
            swal({
                title: "Are you sure?",
                text: "To complete your subscription for this plan",
                buttons: ["CANCEL", "SUBSCRIBE"],
            })
            .then((willSubscribe) => {
                if (willSubscribe) {
                    this.props.onLoadingTrue();

                    var formData = new FormData();
                    formData.append('uid', authUser.uid);
                    formData.append('planId', plan.id);

                    Server.post(ApiPaths.UPDATE_USER_PLAN, formData)
                    .then(response => {
                        if(response.data.statusCode === 200) {
                            swal("Subscription Success", "You have been subscribed successfully to the selected plan", {
                                icon: "success",
                            });
                            
                            // Update user status
                            authUser.status = 3;
                            localStorage.setItem('auth_user', JSON.stringify(authUser));
                            this.props.onSetAuthUser(authUser);
                        } else {
                            swal("Subscription failed", "Please try again to complete your suscription");
                        }
                    });
    
                    this.props.onLoadingFalse();
                }
            }).catch(() => {
                swal("Subscription failed", "Please try again to complete your suscription");
            });
        } else {

            swal({
                title: "Are you sure?",
                text: "You will be redirected to payment gateway to complete your subscription for this plan",
                buttons: ["CANCEL", "SUBSCRIBE"],
            })
            .then((willSubscribe) => {
                if (willSubscribe) {
                    this.props.onLoadingTrue();
    
                    var amount = plan.amount;
    
                    var formData = new FormData();
                    formData.append('userUid', authUser.uid);
                    formData.append('amount', plan.amount);
                    formData.append('email', authUser.email);
                    formData.append('planId', plan.id);
    
                    // Create order api 
                    Server.post(ApiPaths.CREATE_ORDER, formData)
                    .then(response => {
                        if(response.data.statusCode === 200) {
                            const {data} = response.data;
                            var orderId = data.orderId;
                            var razorpayKey = data.key;
    
                            // Initialize payment
                            var options = {
                                "key": razorpayKey,
                                "amount": (amount * 100),
                                "name": plan.plan_name !== '' ? plan.plan_name : 'Subscription',
                                "description": "Jagrati subscription",
                                "image": Images.logo,
                                "order_id" : orderId,
                                "theme" : {
                                    "color": "#007bff",
                                },
                                "handler": function (responseData){
                                    if(responseData && responseData.razorpay_payment_id !== '') {
                                        var respFormData = new FormData();
                                        respFormData.append('razorpay_payment_id', responseData.razorpay_payment_id);
                                        respFormData.append('razorpay_order_id', responseData.razorpay_order_id);
                                        respFormData.append('razorpay_signature', responseData.razorpay_signature);
    
                                        // Payment success api
                                        Server.post(ApiPaths.PAYMENT_SUCCESS + authUser.uid, respFormData)
                                        .then(resp => {
                                            if(resp.data.statusCode === 200) {
    
                                                swal("Payment Success", "Payment is recieved for plan you have subscribed", {
                                                    icon: "success",
                                                });
    
                                                resp.data.data.status = 3; // Update user status;
    
                                                this.props.onDisplayMessage('Payment success, Subscription is completed');
    
                                                localStorage.setItem('auth_user', JSON.stringify(resp.data.data));
                                                this.props.onSetAuthUser(resp.data.data);
    
                                                // Redirecting to task page after successfull payment
                                                setTimeout(function() {
                                                    window.location.href = '/task-days';
                                                }, 2400);
                                            }
                                        });
                                    } else {
                                        swal("Payment failed", "Please try again to complete your suscription");
                                        this.props.onDisplayError('Payment failed, Please try again');
                                    }
                                }.bind(this),
                                "prefill" : {
                                    "contact" : authUser.mobile,
                                    "email" : authUser.email
                                }
                            }
                    
                            let rzp = new window.Razorpay(options); rzp.open();
                        }
                    })
                    .catch(() => {
                        swal("Payment failed", "Please try again to complete your suscription");
                    });;
    
                    this.props.onLoadingFalse();
                }
            });
        }

    }

    renderPlanFeatures = (plans) => {
        var features = [];

        if(plans && plans.length > 0) {
            var plan_description = plans[0].description;
            if(plan_description && plan_description !== '') {
                features = plan_description.split('||');
            }
        }

        if(features && features.length > 0) {
            return (
                <tbody>
                    {features.map((feature, featIndex) => (
                        <tr>
                            {plans.map((plan, planIndex) => (
                                this.renderFeature(plan, planIndex, featIndex)
                            ))}
                        </tr>
                    ))}

                    {/* Render buy button */}
                    <tr>
                        {plans.map((plan, planIndex) => (
                            (planIndex === 0) ? (
                                <td key={planIndex}></td>
                            ) : (
                                <td key={planIndex}>
                                    <h5 className="mt-2"><b>{'Rs. ' + plan.amount}</b></h5>
                                    <Button className="mt-2" variant="contained" color="primary" onClick={() => this.handleCheckOut(plan)}><b>{'SUBSCRIBE'}</b></Button>
                                </td>
                            )
                        ))}
                    </tr>
                </tbody>
            )
        }
    }

    renderFeature = (plan, planIndex, featIndex) => {
        var features = [];
        var subFeatures = [];

        // Split descriptions
        if(plan) {
            var plan_description = plan.description;
            if(plan_description && plan_description !== '') {
                features = plan_description.split('||');
            }
        }

        // Split descriptions into sub texts
        if(planIndex !== 0 && features && features.length > featIndex && features[featIndex] !== '') {
            subFeatures = features[featIndex].split(':');
        }
        
        if(planIndex === 0) {
            return (
                <td style={{textAlign: 'center', verticalAlign: 'middle'}} key={planIndex}><b>{features[featIndex]}</b></td>
            )
        } else {
            return (
                <td style={{textAlign: 'center', verticalAlign: 'middle'}} key={planIndex}><img src={subFeatures[0] === "Y" ? Images.yes : Images.no} height={subFeatures[0] === "Y" ? 40 : 30} alt="" /> <br/> <span className="small_text mt-1">{subFeatures[1]}</span></td>
            )
        }
    }

    renderSubscriptionPlan = (plans) => {
        if(plans && plans.length > 0) {
            return (
                <div className="row mt-4 justify-content-center p-3">
                    <table className="table table-striped table-bordered" style={{textAlign: 'center', verticalAlign: 'middle'}}>
                        <thead>
                            <tr>
                                {plans.map((plan, index) => (
                                    <th className="bg-blue text-light" width={index === 0 ? "50%" : "15%"} key={index}>{plan.plan_name}</th>    
                                ))}
                            </tr>
                        </thead>
                        
                        {this.renderPlanFeatures(plans)}
                    </table>
                </div>
            )
        } else {
            return (
                <div className="row mt-4">
                    <div className="col">
                        <h4 className="my-5">{'No plans found'}</h4>
                    </div>
                </div>
            )
        }
    }

    renderPackageButtons = () => {
        const {plans} = this.props;

        if(plans && plans.length > 0) {
            plans.shift(); // Remove first plan from plans
            
            return (
                <div className="row" style={{position: "absolute", bottom: 35, right: '5%', left: '8%', zIndex: 999}}>
                    {plans.map((plan, index) => (
                        <div key={index} className="col">
                            <button type="button" className={"btn px-5 payButton payButton" + (index + 1)} onClick={() => this.handleCheckOut(plan)}><b>{plan.amount > 0 ? 'PAY Rs. ' + plan.amount : 'FREE'}</b></button>
                        </div>
                    ))}
                    <div className="col-md-1"/>
                </div>
            )
        } else return null;
    }

    render() {
        

        return (
            <div className="row">
                <div className="col">
                    <img src={Images.payment} alt="" className="img-fluid" />
                    {this.renderPackageButtons()}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        auth_user: state.home.auth_user,
        plans: state.task.subscription_plans,
    }
}

const mapActionsToProps = {
    onGetPlans: getSubscriptionPlans,
    onDisplayError: displayError,
    onDisplayMessage: displayMessage,
    onLoadingTrue: mainLoadingTrue,
    onLoadingFalse: mainLoadingFalse,
    onSetAuthUser: setAuthUser,
}

export default connect(mapStatesToProps, mapActionsToProps) (PaymentScreen);
