import React from 'react';
import {Button} from '@material-ui/core';
import Images from '../../../../assets/images/';
import { STATE_KEYS } from '../../../../assets/constants';

const handleSplitString = (string, seperator) => {
    var splitedString = string.split(seperator);
    return splitedString;
}

class SeatingQuiz extends React.Component {

    componentDidMount() {
        this.taskInterval = setInterval(() => {
            this.handleTaskTimeInterval();
        }, 1000);
    }

    handleTaskTimeInterval = () => {
        const {showQuestion, questions, taskTime} = this.props;
        const question = questions[showQuestion];
        var timeLimit = (60 * question.taskTime); // Maximum time limit

        // Move to next question on task time over
        if(question.taskType === 1 && taskTime >= timeLimit) {
            this.props.handleAutoMove();
        }
    }

    componentWillUnmount() {
        clearInterval(this.taskInterval);
    }

    handleQuestionAnswer = (e, answerNumber, answerSaved) => {
        const {showQuestion, questions, userAnswers, taskTime} = this.props;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);

        var answer = e.target.value;
        // alert(answer);

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0) {
            userAnswers[alreadyExistsIndex].answer[answerNumber] = answer;
            userAnswers[alreadyExistsIndex].timeTaken = taskTime;
        } else {
            answerSaved[answerNumber] = answer;
            userAnswers.push({question: question.id, answer: answerSaved, timeTaken: taskTime});
        }

        // Add new answer for question
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);      

        // console.log("User answers", userAnswers);
    }

    renderButtons = (questions, showQuestion) => {
        return (
            <div className="align-center mt-4">
                {(questions.length - 1) !== showQuestion ? 
                    <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                : 
                    <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                }
            </div>
        )
    }

    renderSelectInput = (answerNumber, options, type, val = 0) => {
        const {showQuestion, questions, userAnswers} = this.props;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''];

        // Get saved answer
        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        // Convet to array if it is stringified
        if(Array.isArray(answerSaved) === false) {
            answerSaved = JSON.parse(answerSaved);
        }

        // Restrict answer length based on task type
        if(question.taskType === 1) {
            answerSaved.length = 12;

            // Add default value for first option
            if(val === 1 && options.length > 0) {
                answerSaved[0] = options[1] !== '' ? options[1].trim() : options[1];
            }
        } else {
            answerSaved.length = 30;
        }
        
        if(options.length > 0) {
            return (
                <div className="form-group">
                    <select className="form-control" value={answerSaved.length > 0 ? answerSaved[answerNumber] : ''} onChange={(e) => this.handleQuestionAnswer(e, answerNumber, answerSaved)} disabled={val === 1}>
                        {val === 0 && <option value="">Select option</option>}

                        {options.map((option, index) => (
                            (type === 1 && index === 0) ?
                                null
                            : 
                                <option key={index} disabled={(type === 1 && index === 1) || (answerSaved.includes(option.trim()))} className={((type === 1 && index === 1) || (answerSaved.includes(option.trim()))) && "inActiveOption"} value={option !== '' ? option.trim() : option}>{option.replace('O:', '')}</option>
                        ))}
                    </select>
                </div>
            )
        }
    }

    renderTestView = () => {
        const {showQuestion, questions} = this.props;
        const question = questions[showQuestion];
        var answerClues = [];
        var headers = [];
        var sideHeaders = [];
        var firstOptions = [];
        var secondOptions = [];
        var thirdOptions = [];
        var fourthOptions = [];
        var fifthOptions = [];
        var sixthOptions = [];

        if(question.taskDesc !== '') {
            answerClues = question.taskDesc.split('||clue:');

            if(answerClues.length > 0) {
                var answerData = answerClues;
                headers = answerClues[answerClues.length - 1].split('||Header:');

                if(headers.length > 0) {
                    answerClues.splice(answerData.length - 1)
                    answerClues.push(headers[0]);
                }
            }
        }

        if(question.taskOption1 && question.taskOption1 !== '') {
            var option1Headers = handleSplitString(question.taskOption1, ':');
            sideHeaders.push(option1Headers[0]);
            firstOptions = handleSplitString(option1Headers[1], '||');
        }

        if(question.taskOption2 && question.taskOption2 !== '') {
            var option2Headers = handleSplitString(question.taskOption2, ':');
            sideHeaders.push(option2Headers[0]);
            secondOptions = handleSplitString(option2Headers[1], '||');
        }

        if(question.taskOption3 && question.taskOption3 !== '') {
            var option3Headers = handleSplitString(question.taskOption3, ':');
            sideHeaders.push(option3Headers[0]);
            thirdOptions = handleSplitString(option3Headers[1], '||');
        }

        if(question.taskOption4 && question.taskOption4 !== '') {
            var option4Headers = handleSplitString(question.taskOption4, ':');
            sideHeaders.push(option4Headers[0]);
            fourthOptions = handleSplitString(option4Headers[1], '||');
        }

        if(question.taskOption5 && question.taskOption5 !== '') {
            var option5Headers = handleSplitString(question.taskOption5, ':');
            sideHeaders.push(option5Headers[0]);
            fifthOptions = handleSplitString(option5Headers[1], '||');
        }

        if(question.taskOption6 && question.taskOption6 !== '') {
            var option6Headers = handleSplitString(question.taskOption6, ':');
            sideHeaders.push(option6Headers[0]);
            sixthOptions = handleSplitString(option6Headers[1], '||');
        }

        if(question.taskType === 1) {
            return (
                <div className="mb-4 pb-2">
                    <div className="text-left">
                        {(answerClues && answerClues.length > 0) && (
                            answerClues.map((clue, index) => (
                                <h6 key={index} className="questions font-weight-bold">{clue.replace('clue:', '')}</h6>
                            ))
                        )}
                    </div>

                    <div className="row mt-4">
                        <div className="col" />
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mb-4">
                            {this.renderSelectInput(0, headers, 1, 1)}
                            {this.renderSelectInput(1, firstOptions, 2)}
                            {this.renderSelectInput(2, secondOptions, 3)}
                        </div>
                        <div className="col" />
                    </div>

                    <div className="row">
                        <div className="col pt-5">
                            <br/><br/><br/>
                            {this.renderSelectInput(3, headers, 1)}
                            {this.renderSelectInput(4, firstOptions, 2)}
                            {this.renderSelectInput(5, secondOptions, 3)}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-4">
                            <img src={Images.table} style={{height: '300px', transform: 'rotate(45deg)'}} alt="" />
                        </div>
                        <div className="col pt-5">
                            <br/><br/><br/>
                            {this.renderSelectInput(6, headers, 1)}
                            {this.renderSelectInput(7, firstOptions, 2)}
                            {this.renderSelectInput(8, secondOptions, 3)}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col" />
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-5">
                            {this.renderSelectInput(9, headers, 1)}
                            {this.renderSelectInput(10, firstOptions, 2)}
                            {this.renderSelectInput(11, secondOptions, 3)}
                        </div>
                        <div className="col" />
                    </div>

                    {/* Show next or submit button based on question */}
                    {this.renderButtons(questions, showQuestion)}
                </div>
            )
        } else {
            return (
                <div className="mb-4 pb-2">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                {(headers && headers.length > 0) && (
                                    headers.map((header, index) => (
                                        (index !== 0) ?
                                            <th key={index} width="15%" key={index}>{header}</th>
                                        : <th key={index} width="25%"></th>
                                    ))
                                )}
                            </tr>
                        </thead>
                        <tbody className="text-left">
                            <tr>
                                <th width="25%">{sideHeaders[0]}</th>

                                {(firstOptions && firstOptions.length > 0) && (
                                    firstOptions.map((option, index) => (
                                        <th key={index} className="mb-0 pb-0" width="15%">{this.renderSelectInput((0 + index), firstOptions, 2)}</th>
                                    ))
                                )}
                            </tr>
                            <tr>
                                <th width="25%">{sideHeaders[1]}</th>

                                {(secondOptions && secondOptions.length > 0) && (
                                    secondOptions.map((option, index) => (
                                        <th key={index} className="mb-0 pb-0" width="15%">{this.renderSelectInput((5 + index), secondOptions, 3)}</th>
                                    ))
                                )}
                            </tr>
                            <tr>
                                <th width="25%">{sideHeaders[2]}</th>

                                {(thirdOptions && thirdOptions.length > 0) && (
                                    thirdOptions.map((option, index) => (
                                        <th key={index} className="mb-0 pb-0" width="15%">{this.renderSelectInput((10 + index), thirdOptions, 4)}</th>
                                    ))
                                )}
                            </tr>
                            <tr>
                                <th width="25%">{sideHeaders[3]}</th>

                                {(fourthOptions && fourthOptions.length > 0) && (
                                    fourthOptions.map((option, index) => (
                                        <th key={index} className="mb-0 pb-0" width="15%">{this.renderSelectInput((15 + index), fourthOptions, 5)}</th>
                                    ))
                                )}
                            </tr>
                            <tr>
                                <th width="25%">{sideHeaders[4]}</th>

                                {(fifthOptions && fifthOptions.length > 0) && (
                                    fifthOptions.map((option, index) => (
                                        <th key={index} className="mb-0 pb-0" width="15%">{this.renderSelectInput((20 + index), fifthOptions, 6)}</th>
                                    ))
                                )}
                            </tr>
                            <tr>
                                <th width="25%">{sideHeaders[5]}</th>

                                {(sixthOptions && sixthOptions.length > 0) && (
                                    sixthOptions.map((header, index) => (
                                        <th key={index} className="mb-0 pb-0" width="15%">{this.renderSelectInput((25 + index), sixthOptions, 7)}</th>
                                    ))
                                )}
                            </tr>
                        </tbody>
                    </table>
                    
                    <div className="text-left mt-4">
                        {(answerClues && answerClues.length > 0) && (
                            answerClues.map((clue, index) => (
                                <h6 key={index} className="questions font-weight-bold">{clue.replace('clue:', '')}</h6>
                            ))
                        )}
                    </div>

                    {/* Show next or submit button based on question */}
                    {this.renderButtons(questions, showQuestion)}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default SeatingQuiz;