import React from 'react';
import Images from '../../../assets/images/';

class Home extends React.Component {
    // componentDidMount() {
        // alert(!!window["chrome"]);
    // }

    render() {
        return (
            <div className="container mt-5">
                <div className="row">
                    <div className="col pt-4">
                        <img style={{height: '150px'}} src={Images.logo} alt="" />

                        <h1 style={{color: 'rgba(0,0,0,0.6)', marginTop: '80px'}}>Welcome To</h1>
                        <h1 style={{color: 'rgba(0,0,0,0.7)', marginTop: '20px', fontSize: '120px', wordSpacing: '50px'}}>JAGRATI</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default (Home);