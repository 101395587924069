import React from 'react';
import {Button} from '@material-ui/core';
import Timer from '../../../common/timer';
import swal from 'sweetalert';
import { getTaskDetails, getTaskQuestions } from '../../../../redux/actions/Tasks';
import {connect} from 'react-redux';
import BrainDrain from '../braindrain/';
import Survey from '../survey/';
import Dimension from '../dimensionsQuiz/';
import SituationBased from '../situtationBased/';
import InterQuestion from '../interQuestionQuiz/';
import IntraQuestion from '../intraQuestionQuiz/';
import InCognito from '../incognitoQuiz/';
import Mixer from '../mixer/';
import SecretWord from '../secretWord';
import Seating from '../seating';
import MasterMind from '../masterMind';
import GuessWho from '../guessWho/';
import Handwriting from '../handwritingQuiz/';
import TempoChange from '../tempoChange/';
import TwoTunes from '../twoTunes/';
import Enact from '../enact';
import NameNarrate from '../nameNarrate';
import Fixit from '../fixit';
import WhatNext from '../whatNext';
import PianoKeys from '../pianoKeys';
import Cognizant from '../cognizant';
import {Server, ApiPaths} from '../../../../utils/Server';
import { displayError, mainLoadingFalse, mainLoadingTrue } from '../../../../redux/actions/Home';
import { TASK_ICONS, TASK_ID, NON_EDITABLE_TASKS, TIMER_TASKS, STATE_KEYS, WAITING_TIME } from '../../../../assets/constants';
import Images from '../../../../assets/images';

const RestrictReload = (e) => {
    if(e.keyCode === 116 || (e.metaKey && e.keyCode === 82) || (e.ctrlKey && e.keyCode === 82) || (e.ctrlKey && e.shiftKey && e.keyCode === 82)) {
        // F5 and command + R and ctrl + R and ctrl + shift + R
       e.preventDefault();
    }
};

class TaskHome extends React.Component {
    constructor(props) {
        super(props);
        const {params} = props.match;
        this.state = {taskID: params.taskID, showStartButton: false, startTest: false, iconLoader: true, showQuestion: 0, userAnswers: [], isVisited: 0, maxAnswers: [1,2,3,4,5,6], error: '', charactersAllowed: 512, currentTime: {minutes: 0, seconds: 0}, taskTime: 0, isTimeOver: false, editRestrictedTasks: NON_EDITABLE_TASKS, taskTimerTasks: TIMER_TASKS};
    }

    componentDidMount() {
        // Restrict reload options
        document.addEventListener('keydown', e => RestrictReload(e));

        // Initial icon loader
        setTimeout(() => {
            this.setState({iconLoader: false});
        }, WAITING_TIME.ICON_LOADING);

        // Show start button
        setTimeout(() => {
            this.setState({showStartButton: true});
        }, WAITING_TIME.TASK_LOADING);

        // Api loader
        this.handleApiCall();
    }

    // Handle main test timer
    handleTestTime = () => {
        const {currentTime, startTest} = this.state
        const {questions} = this.props;

        if(questions && questions.length > 0 && startTest) {
            if (currentTime.seconds < 59) {
                this.setState({currentTime: {
                    minutes: currentTime.minutes,
                    seconds: currentTime.seconds + 1
                }});
            }
            if (currentTime.seconds === 59) {
                this.setState({currentTime: {
                    minutes: currentTime.minutes + 1,
                    seconds: 0
                }});
            }
        }
    }

    // Handle each task time
    handleTaskTime = () => {
        const {taskTime, startTest} = this.state
        const {questions} = this.props;

        if(questions && questions.length > 0 && startTest) {
            this.setState({taskTime: (taskTime + 1)});
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", e => RestrictReload(e));
        clearInterval(this.myInterval)
    }

    handleSetState = (key, value) => {
        if(key === STATE_KEYS.ANSWERS) {
            this.setState({userAnswers: value});
        } else if(key === STATE_KEYS.VISITED) {
            this.setState({isVisited: value});
        } else if(key === STATE_KEYS.ERROR) {
            this.setState({error: value});
        } else if(key === STATE_KEYS.QUESTION) {
            this.setState({showQuestion: value});
        } else if(key === '') {
            // this.setState({isVisited: value});
        }
    }

    handleTaskReload = () => {
        this.handleApiCall();
    }

    handleApiCall = () => {
        const {taskID} = this.state;

        if (taskID && taskID !== undefined && taskID !== '') {
            this.props.onGetTaskDetails(taskID);
        }
    }

    handleStartTest = () => {
        const {taskData} = this.props;

        swal({
            title: "All the best",
            text: "You have total of " + taskData.question_max + " questions to be answered and have maximum of " + taskData.task_timer_max + " minutes to complete this test, Timer will start running for your test as soon as you enter the test",
            buttons: ["Cancel", "Start Test"],
        })
        .then((willExit) => {
            if (willExit) {
                this.handleGetQuestions();
            }
        });
    }
  
    handleGetQuestions = () => {
        const {taskData, auth_user} = this.props;

        if (taskData && taskData !== undefined && taskData.responseStatus === 0) {
            var formData = new FormData();
            formData.append('taskGId', taskData.taskGroupUid);
            formData.append('userId', auth_user.uid);
            this.props.onGetTaskQuestions(formData).then(() => {
                this.setState({startTest: true, showQuestion: 0, isTimeOver: false});
                
                // Calculate exam time
                this.myInterval = setInterval(() => {
                    this.handleTestTime() // Main task timer
                    this.handleTaskTime() // Each question timer
                }, 1000);
            });
        }
    }

    renderTestLinks = (taskData, questions) => {
        const {showQuestion, isVisited, userAnswers} = this.state;
        
        if(questions && questions.length > 0) {
            return (
                <div>
                    {questions.map((question, index) => (
                        this.handleButtonStyle(index, taskData, questions, userAnswers, showQuestion, isVisited)
                    ))}
                </div>
            )
        } else {
            return null;
        }
    }

    handleButtonStyle = (index, taskData, questions, userAnswers, showQuestion, isVisited) => {
        const {editRestrictedTasks} = this.state;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questions[index].id);
        var isEditRestricted = editRestrictedTasks.includes(taskData.id);
        
        if(taskData && taskData.task_per_screen === 1 && isEditRestricted === false) {
            if((index !== showQuestion && alreadyExistsIndex >= 0)) {
                // If question is visited and already answered
                return <Button key={index} className="p-0 ml-1 mb-2" variant="contained" title={"Answered"} style={{backgroundColor: "green", color: "white"}} disabled={isVisited < index} onClick={() => this.setState({showQuestion: index})}>{index + 1}</Button>
            } else if((index < isVisited && alreadyExistsIndex === -1) && index !== showQuestion) {
                // If question is visited but not answered
                return <Button key={index} className="p-0 ml-1 mb-2" variant="contained" title={"Unanswered"} style={{backgroundColor: "yellow", color: "black"}} disabled={isVisited < index} onClick={() => this.setState({showQuestion: index})}>{index + 1}</Button>
            } else if(index < isVisited || index === showQuestion) {
                // If question is on focus
                return <Button key={index} className="p-0 ml-1 mb-2" variant="contained" title={"Active"} style={{backgroundColor: "blue", color: "white"}} disabled={isVisited < index} onClick={() => this.setState({showQuestion: index})}>{index + 1}</Button>
            } else {
                // Is not visited at all
                return <Button key={index} className="p-0 ml-1 mb-2" variant="contained" style={{backgroundColor: "grey", color: "white"}} disabled={isVisited < index} onClick={() => this.setState({showQuestion: index})}>{index + 1}</Button>
            }
        } else {
            if(alreadyExistsIndex >= 0) {
                // If question is visited and already answered
                return <Button key={index} className="p-0 ml-1 mb-2" variant="contained" title={"Answered"} style={{backgroundColor: "green", color: "white"}} disabled onClick={() => this.setState({showQuestion: index})}>{index + 1}</Button>
            } else {
                // If question is visited but not answered
                return <Button key={index} className="p-0 ml-1 mb-2" variant="contained" title={"Unanswered"} style={{backgroundColor: "yellow", color: "black"}} disabled onClick={() => this.setState({showQuestion: index})}>{index + 1}</Button>
            }
        }
    }
    
    handleNextButton = () => {
        const {showQuestion, isVisited, editRestrictedTasks} = this.state;
        const {taskData} = this.props;
        var isEditRestricted = editRestrictedTasks.includes(taskData.id);

        if(isEditRestricted) {
            swal({
                title: "Are you sure?",
                text: "You can't come back once you move to next one",
                buttons: ["Cancel", "Move"],
            })
            .then((willExit) => {
                if (willExit) {
                    this.setState({showQuestion: showQuestion + 1, isVisited: isVisited > showQuestion ? isVisited : isVisited + 1, taskTime: 0}, () => {
                        // Handle each task time
                        this.handleTaskTime();
                    });
                }
            });
        } else {
            this.setState({showQuestion: showQuestion + 1, isVisited: isVisited > showQuestion ? isVisited : isVisited + 1});
        }
    }

    // Move to next task automatically after task time expire
    handleAutoMove = () => {
        const {showQuestion, isVisited} = this.state;

        this.setState({showQuestion: showQuestion + 1, isVisited: isVisited > showQuestion ? isVisited : isVisited + 1, taskTime: 0}, () => {
            // Handle each task time
            this.handleTaskTime();
        });
    }

    handleTestSubmit = () => {
        const {userAnswers, taskID} = this.state;
        const {questions} = this.props;
        var unAnswered = questions.length - userAnswers.length;

        // If user not yet answered any of the question
        if(unAnswered === questions.length) {
            swal("Hold On!", "Kindly attempt all the questions to move to the next task.", {
                icon: "warning",
            });
        }

        // If user attended atleast 1 question
        else {
            // User should answer only 2 question for Name and Narrate
            if(parseInt(taskID) === TASK_ID.NAME_NARRATE) {
                unAnswered = userAnswers.length >= 2 ? 0 : (questions.length - 2);  
            }
            
            swal({
                title: "Are you sure?",
                text: "You have " + unAnswered + " unanswered questions, Once you submit, you will not be able to go back!",
                buttons: ["Cancel", "Submit"],
            })
            .then((willSubmit) => {
                if (willSubmit) {
                    // redierct to preview screen
                    this.handleSubmitTaskAnswers();
                } else {
                    swal("You are in", "You can continue your exam or you can submit answers!");
                }
            });
        }
    }

    // Times up function 
    handleTimesUp = () => {
        if(!this.state.isTimeOver) {
            this.setState({isTimeOver: true}, () => {
                swal("Times Up!", "Your test is ended, redirecting...", {
                    icon: "warning",
                });

                setTimeout(() => this.handleSubmitTaskAnswers(), 3000);
                // console.log('Times up!...');
            });
        }
    }

    // Submit answers and redirect
    handleSubmitTaskAnswers = () => {
        const {taskData, auth_user, questions} = this.props;
        const {userAnswers, currentTime, taskID} = this.state;
        var answered = (userAnswers.length);
        var unanswered = (questions.length - userAnswers.length);

        // Reset task timer
        this.setState({taskTime: 0});

        if(userAnswers.length > 0) {
            userAnswers.map(ans => (
                ans.answer = JSON.stringify(ans.answer)
            ));
        }

        // User should answer only 2 question for Name and Narrate
        if(parseInt(taskID) === TASK_ID.NAME_NARRATE) {
            answered = userAnswers.length >= 2 ? 2 : (userAnswers.length);
            unanswered = userAnswers.length >= 2 ? 0 : (questions.length - 2);  
        }
        
        var formData = new FormData();
        formData.append('taskGId', taskData.id);
        formData.append('taskGroupTime', JSON.stringify(currentTime));
        formData.append('taskAnswers', JSON.stringify(userAnswers));

        // Submit answer api call
        this.props.onLoadingTrue();
        Server.post(ApiPaths.SUBMIT_ANSWERS + (auth_user.uid), formData)
        .then(response => {
            const { data } = response;
            if(data.statusCode === 200) {

                swal("All the best", "Your answers are submitted", {
                    icon: "success",
                });

                // Redirect to preview screen
                setTimeout(() => {
                    window.location.href = '/preview?answered=' + answered + '&unanswered=' + unanswered + '&minutes=' + currentTime.minutes + '&seconds=' + currentTime.seconds + '&day=' + taskData.dayType + '&weight=' + taskData.weight;
                }, 3000);
            } else {
                this.handleSubmitAnswersFailed();
            }
        })
        .catch(err => {
            
        })

        this.props.onLoadingFalse();
    }

    // Resubmit answers if failed to submit
    handleSubmitAnswersFailed = () => {
        swal({
            title: "OOPS!.. Something went wrong",
            text: "Unable to submit your answers, Click on Resubmit to submit your answers, Do not close otherwise your answers will be lost",
            buttons: ["Cancel", "Resubmit"],
        })
        .then((willSubmit) => {
            if (willSubmit) {
                // Resubmit answers
                this.handleSubmitTaskAnswers();
            }
        });
    }

    // Exit test
    handleExitTest = () => {
        swal({
            title: "Are you sure?",
            text: "Once exit, you will not be able to continue this test and your answers will be lost!",
            buttons: true,
            dangerMode: true,
          })
          .then((willExit) => {
            if (willExit) {
              this.setState({startTest: false, showQuestion: 0, userAnswers: [], isVisited: 0, currentTime: {minutes: 0, seconds: 0}});

              swal("OOPS!..", "You are out of exam and all answers are lost!", {
                icon: "success",
              });
            } else {
              swal("You are safe", "You can continue your exam now!");
            }
        });
    }

    renderTestScreen = (taskData, questions) => {
        if(questions && questions.length > 0) {
            if(taskData.id === TASK_ID.BRAIN_DRAIN) {
                return <BrainDrain handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.METRIC_1) {
                return <Survey handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.DIMENSION) {
                return <Dimension handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.REFLECTION) {
                return <SituationBased handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.METRIC_2) {
                return <InterQuestion handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.METRIC_3) {
                return <IntraQuestion handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.INCOGNITO) {
                return <InCognito handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.MIXER) {
                return <Mixer handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.SECRET_WORDS) {
                return <SecretWord handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.SEATING) {
                return <Seating handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} handleAutoMove={this.handleAutoMove} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.MASTER_MIND) {
                return <MasterMind handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            // } else if(taskData.id === TASK_ID.GUESS_WHO) {
            //     return <GuessWho handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} handleTimesUp={() => this.handleTimesUp()} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.HANDWRITING) {
                return <Handwriting handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.TEMPO) {
                return <TempoChange handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.TWO_TUNES) {
                return <TwoTunes handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.ENACT) {
                return <Enact handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.NAME_NARRATE) {
                return <NameNarrate handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} maxCharacters={1000} />
            } else if(taskData.id === TASK_ID.FIXIT) {
                return <Fixit handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.WHATS_NEXT) {
                return <WhatNext handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.PIANO) {
                return <PianoKeys handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} />
            } else if(taskData.id === TASK_ID.COGNIZANT) {
                return <Cognizant handleTestSubmit={this.handleTestSubmit} handleSetState={this.handleSetState} handleNextButton={this.handleNextButton} {...this.state} {...this.props} maxCharacters={this.state.charactersAllowed} />
            } else {
                return null;
            }
        } else {
            return (
                <div className="text-center">
                    <h5 className="card-title">{'Task questions not available, Please try again'}</h5>
                    <button type="button" className="btn btn-md btn-primary mt-3" onClick={() => this.handleStartTest()}>{'TRY AGAIN'}</button>
                </div>
            )
        }
    }

    render() {
        const { taskData, questions } = this.props;
        const {startTest, iconLoader, showStartButton, taskID} = this.state;

        if(iconLoader) {
            return (
                <div style={{position: 'absolute', top:'10%', right: 0, left: 0, bottom: '20%', zIndex: 999}}>
                    <div className="row">
                        <div className="col" />
                        <div className="col-md-6 col-12">
                            <img src={TASK_ICONS[parseInt(taskID)]} className="img-fluid" alt="" />
                            <br/>
                            <img src={Images.dotLoader} className="img-fluid" alt="" />
                        </div>
                        <div className="col" />
                    </div>
                </div>
            )
        } else {
            if(Object.entries(taskData).length > 0) {
                if(!startTest) {
                    return (
                        <div className="container pt-3">
                            <div className="row">
                                <div className="col">
                                    <h2 className="text-uppercase" style={{color: '#233f78'}}>{taskData.group_name}</h2>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-3">
                                    <h6 className="text-left">Day: {taskData.day_type}</h6>
                                    <h6 className="text-left">Questions: {parseInt(taskID) === TASK_ID.NAME_NARRATE ? 2 : taskData.question_max}</h6>
                                    <h6 className="text-left">Time: {taskData.task_timer_max + ' minutes'}</h6>
                                    <div className="text-left" dangerouslySetInnerHTML={{__html: taskData.description}} />
                                    <br/>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 py-2 px-4">
                                    <img src={TASK_ICONS[taskData.id]} className="img-fluid" alt="" />
                                </div>
                            </div>

                            <div className="row">
                                {showStartButton && 
                                    <div className="col">
                                        {(taskData.responseStatus === 0) ? 
                                            <div className="card">
                                                <div className="card-body">
                                                    <p className="card-text"><input type="checkbox" className="mr-1" checked onChange={() => {}} /> I have read all the above instructions completely</p>
                                                    <Button className="py-2 px-3" variant="contained" color="primary" onClick={() => this.handleStartTest()}><b>Start Test</b></Button>
                                                </div>
                                            </div>
                                        :
                                            <div className="container">
                                                <div className="row">
                                                    <div class="col card mt-3 p-4">
                                                        <h6>You already have been attended this test, Please move on to next task assigned.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    );
                } else if(startTest) {
                    return (
                        <div className="container">
                            <div className="row">
                                <div className="col mt-2">
                                    <h4>Time Remaining: <Timer minutes={taskData.task_timer_max} start={questions.length > 0} onTimesUp={() => setTimeout(()=> this.handleTimesUp(), 500)} /></h4>
                                </div>
                            </div>
                
                            <div className="row mt-3">
                                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 offset-lg-1">
                                    {this.renderTestLinks(taskData, questions)}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col mr-2 mt-2">
                                    {this.renderTestScreen(taskData, questions)}
                                </div>
                            </div>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                <h5 className="card-title">{'Task data not available, Please try again'}</h5>

                                <button type="button" className="btn btn-md btn-primary mt-3" onClick={() => this.handleTaskReload()}>{'TRY AGAIN'}</button>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

const mapStatesToProps = (state) => {
    return {
        taskData: state.task.taskData,
        questions: state.task.task_questions,
        auth_user: state.home.auth_user
    }
};

const mapActionsToProps = {
    onGetTaskDetails: getTaskDetails,
    onGetTaskQuestions: getTaskQuestions,
    onLoadingTrue: mainLoadingTrue,
    onLoadingFalse: mainLoadingFalse,
    onDisplayError: displayError,
}

export default connect(mapStatesToProps, mapActionsToProps) (TaskHome);
