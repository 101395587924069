import React from 'react';
import { connect } from 'react-redux';
import {displayMessage} from '../../redux/actions/Home';
import queryString from 'query-string';

class TermsAndConditions extends React.Component {

    constructor(props) {
        super(props);
        const params = queryString.parse(this.props.location.search);
        this.state = {email: params.email ? params.email : ''};
    }

    handleAcceptDeny = (type) => {
        if(type === 1) {
            window.location.href = '/?accept_terms=1&email=' + this.state.email;
        } else {
            window.location.href = '/?email=' + this.state.email;
        }
    }

    render() {
        return (
            <div className="container terms_main_container p-3">
                <div className="row">
                    <div className="col terms_container mt-3">
                        <h3 className="text-center text-uppercase mt-4 mb-5"> Terms and conditions </h3>

                        <div className="row description_card">
                            <div className="col">
                                <h4 className="header">INTRODUCTION </h4>
                                <p className="para">These terms and conditions apply to the User who uses the Online Services provided for any payment made to Jagrati Edtech Private Limited Edtech Private Limited. Kindly read these terms and conditions carefully. By authorizing a payment to Jagrati Edtech Private Limited through the online payment service ("the service"), it would be treated as a deemed acceptance to these terms and conditions. Jagrati Edtech Private Limited reserves all the rights to amend these terms and conditions at any time without giving prior notice. These Terms of Use, together with the rest of the Policies (defined below), constitute a binding and enforceable agreement between the User and Razorpay. It is the responsibility of the User to have read the terms and conditions before using the Service.</p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <h4 className="header">Key terms </h4>
                                <p className="para">The following is a summary of the key terms of this service :</p>
                                <p className="para">Payment(s) through this Service may only be made with a Credit Card, Debit card or Net Banking. • Before using this Service, it is recommended that the user shall make necessary enquiry about the charges or fees payable against the Credit/Debit card used from Credit Card or the Debit Card service provider i.e. the respective Bank.</p>
                                <p className="para">The credit card information supplied at the time of using the service is processed by the payment gateway of the service provider and is not supplied to Jagrati Edtech Private Limited. It is the sole responsibility of the User of the service to ensure that the information entered in the relevant fields are correct. It is recommended that you take and retain a copy of the transaction for record keeping purposes, which might assist in resolution of any disputes that may arise out of usage of the service</p>
                                <p className="para">The Applicant agrees, understands and confirms that his/ her personal data including without limitation details relating to debit card/ credit card/net banking transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that Jagrati Edtech Private Limited or the Payment Service Provider(s) have no control over such matters.</p>
                                <p className="para">The service is provided using a payment gateway service provider through a secure website. However, neither the payment gateway service provider nor Jagrati Edtech Private Limited  gives any assurance, that the information so provided online by a user is secured or may be read or intercepted by a third party. Jagrati Edtech Private Limited does not accept or assume any liability in the event of such unauthorized interception, hacking or other unauthorized access to information provided by a user of the service.</p>
                                <p className="para">Jagrati Edtech Private Limited and/or Razor Pay shall not be liable for any inaccuracy, error or delay in, or omission of (a) any data, information or message, or (b) the transmission or delivery of any such data, information or message; or (c) any loss or damage arising from or occasioned by any such inaccuracy, error, delay or omission, non-performance or interruption in any such data, information or message. Under no circumstances shall Jagrati Edtech Private Limited and/or the Payment Service Providers, its employees, directors, and its third party agents involved in processing, delivering or managing the Services, be liable for any direct, indirect, incidental, special or consequential damages, or any damages whatsoever, including punitive or exemplary arising out of or in any way connected with the provision of or any inadequacy or deficiency in the provision of the Services or resulting from unauthorized access or alteration of transmissions of data or arising from suspension or termination of the Service.</p>
                                <p className="para">The Applicant agrees that Jagrati Edtech Private Limited or any of its employees will not be held liable by the Applicant for any loss or damages arising from your use of, or reliance upon the information contained on the Website, or any failure to comply with these Terms and Conditions where such failure is due to circumstance beyond Jagrati Edtech Private Limited’s  reasonable control.</p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <h4 className="header">Debit/Credit Card, Bank Account Details</h4>
                                <p className="para">The Applicant agrees that the debit/credit card details provided by him/ her for use of the aforesaid Service(s) must be correct and accurate and that the Applicant shall not use a Debit/ credit card, that is not lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The Applicant further agrees and undertakes to Provide correct and valid debit/credit card details.</p>
                                <p className="para">The Applicant may pay his/ her application/initial fees to Jagrati Edtech Private Limited by using a debit/credit card or through RAZOR PAY. The Applicant warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her  card / bank details: The Applicant is fully and lawfully entitled to use such credit / debit card, bank account for such transactions;  The Applicant is responsible to ensure that the card/ bank account details provided by him/ her are accurate; The Applicant authorizes debit of the nominated card/ bank account for the Payment of fees selected by such Applicant along with the applicable Fees. o The Applicant is responsible to ensure that sufficient credit is available on the nominated card/ bank account at the time of making the payment to permit the Payment of the dues payable or fees dues selected by the Applicant inclusive of the applicable Fee. No warranty: No warranty, representation or guarantee, express or implied, is given by Jagrati Edtech Private Limited  in respect of the operation of the service. Disclaimer and Limitation of liability: Jagrati Edtech Private Limited does not accept liability for any damage, loss, cost (including legal costs), expenses, indirect losses or consequential damage of any kind which may be suffered or incurred by the User from the use of this service.</p>
                                <p className="para">In General parent / guardian / student may browse this website for obtaining any data or information. However, in order to access the classified information, one needs to use the credentials provided as a Parent / Guardian / Staff / Student user.</p>
                                <p className="para">The information provided by the user on this website shall be kept confidential and shall not be shared with anyone. The DATA will neither be made accessible to third parties nor transferred in any form or manner unless required by law.</p>
                                <p className="para">Jagrati Edtech Private Limited may share your personal information with our bankers for clearing necessary fees payments. This information shall be strictly up to the legal permissible limit and not exceeding.</p>
                                <p className="para">Online fee payment system's purpose to provide flexibility to students by offering an option to pay online using Razor Pay</p>
                                <p className="para">If payment is made by means of a card that you do not personally own, the permission of the card owner must always be obtained to make payments using the card. In using this system you confirm that you have such permission.</p>
                                <p className="para">Information related to payments using debit or credit card is not accessed or stored by the organisation. </p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <h4 className="header">No Warranty</h4>
                                <p className="para">The information and materials contained in this site including, graphics, links or other items are provided as on “As Is” and “As Available” basis by the Institute which organized and tries to provide information accurately and expressly disclaims liability for error or omission in this information and materials. No warranty of any kind, implied, express or statutory shall be given by the Institute shall not be limited to the warranty of fitness for a particular purpose and freedom from computer virus is given in conjunction with the information and materials</p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <h4 className="header">Limitation of Liability</h4>
                                <p className="para">In no event, Institute will be liable for any damage direct or indirect losses or expenses arising in connection with site or use thereof inability to use by any person delay of operation or transaction, computer virus etc</p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <p className="para">Please read the terms set out hereunder carefully before agreeing to the same. If you do not agree to these Terms of Use (including any referenced policies or guidelines), please immediately terminate your use of the Website. You can accept the Terms of Use by:</p>
                                <p className="para">Clicking to accept or agree to the Terms of Use, where this option is made available to you by Razorpay in the User interface for any particular Service; or</p>
                                <p className="para">Accessing, testing or actually using the Services. In this case, you understand and agree that Razorpay will treat your use of the Services as acceptance of the Terms of Use from that point onwards.</p>
                                <p className="para">Razorpay's "Payment Gateway" product offers the Client a secure payment solution for their Platform and mobile app. It enables the Client to receive payments from customers using various payment methods during checkout. Client can access real-time data and insights on money flow to take informed business decisions.</p>
                                <p className="para">For the purpose of these Terms of Use, wherever the context so requires, the term "User" shall mean and include any natural or legal person who has agreed to these Terms of Use on behalf of itself or any other legal entity.</p>
                                <p className="para">It is clarified that the Privacy Policy (that is provided separately), form an integral part of these Terms of Use and should be read contemporaneously with the Terms of Use. Illegality or unenforceability of one or more provisions of these Terms of Use shall not affect the legality and enforceability of the other terms of the Terms of Use. For avoidance of doubt, if any of the provisions becomes void or unenforceable, the rest of the provisions of these Terms of Use shall be binding upon the User.</p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <h4 className="header">Security</h4>
                                <p className="para">All payment details which are entered through this payment gateway are encrypted when the Student, or third party making payment, enters them. obtained by such other persons, during the online payment process or in respect of any omission Variations to the Terms and Conditions: Jagrati Edtech Private Limited reserves the right to vary these Terms and Conditions from time to time and the current version will be that published on this website</p>
                                <p className="para">We reserve the right to decline the acceptance of an online payment if your account is in default for any reason. Jagrati Edtech Private Limited  may also make additions/deletions/alteration to the services offered, at its sole discretion. We reserve the right to withdraw the service at any time at its discretion. We retain the right to change the terms and conditions for online fees payments, without any prior notice.</p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <h4 className="header">Disclaimer</h4>
                                <p className="para">THE INFORMATION CONTAINED IN THIS ASSESSMENT & REPORT IS FOR REFERENCE PURPOSES ONLY AND JAGRATI TEAM DOES NOT AND CANNOT PROVIDE ANY WARRANTY, GUARANTEE OR PROMISE, WHETHER EXPRESS OR IMPLIED, CONCERNING THE CONTENT PROVIDED IN THIS REPORT OR ITS COMPLETENESS. WE CANNOT AND DO NOT PREDICT FUTURE. NEITHER DO WE GUARANTEE THE SUCCESS OR FAILURE OF ANY PARTICULAR STUDENT ASSESSED IN THIS PROGRAM USING OUR METHODS.</p>
                                <p className="para">TERMS & CONDITIONS</p>
                                <p className="para">1. The pictures uploaded during the assessment is strictly for Professional Assessment purpose and Jagrati will not be using this for any unintended purposes or will not share this with any 3rd parties.</p>
                                <p className="para">2. The data collated by Jagrati is for its sole purpose and will not be shared with anyone. Also, it is the sole right of the Jagrati to use/interpret it. Users can not question the methodology of assessment and authenticity of our evaluation.</p>
                                <p className="para">3. The questions/pictures/videos/audios or any other content used in the process is the sole right of the Jagrati and this can not be used/copied or replicated in any manner.</p>
                                <p className="para">4. In case of any dissatisfaction or disagreement between users & Jagrati, Jagrati will not be liable for any refund of amount collected.</p>
                                <p className="para">5. Report generated is purely based on the user’s performance during the assessment.</p>
                            </div>
                        </div>

                        <div className="row description_card">
                            <div className="col">
                                <button className="btn btn-primary btn-lg" onClick={() => this.handleAcceptDeny(1)}>{'ACCEPT'}</button>
                                <button className="btn btn-danger btn-lg ml-3" onClick={() => this.handleAcceptDeny(2)}>{'DENY'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapActionsToProps = {
    onDisplayMessage: displayMessage,
}

export default connect(null, mapActionsToProps) (TermsAndConditions);
