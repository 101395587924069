import React from 'react';
import { connect } from 'react-redux';
import {mainLoadingTrue, mainLoadingFalse, setAuthUser, displayError, displayMessage} from '../../../redux/actions/Home';
import {Server, ApiPaths, SetAuthToken} from '../../../utils/Server';
import moment from 'moment';
import swal from 'sweetalert';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        const {auth_user} = props;
        this.state = {
            firstName: auth_user ? auth_user.firstName : '',
            lastName: auth_user ? auth_user.lastName : '',
            email: auth_user ? auth_user.email : '',
            mobile: auth_user ? auth_user.mobile : '',
            dob: auth_user ? auth_user.dob : '',
            age: auth_user ? auth_user.age : '',
            clazz: auth_user ? auth_user.clazz : '',
            institution: auth_user ? auth_user.institution : '',
            fatherName: auth_user ? auth_user.fatherName : '',
            fatherOccupation: auth_user ? auth_user.fatherOccupation : '',
            motherName: auth_user ? auth_user.motherName : '',
            motherOccupation: auth_user ? auth_user.motherOccupation : '',
            address1: auth_user ? auth_user.address1 : '',
            address2: auth_user ? auth_user.address2 : '',
            address3: auth_user ? auth_user.address3 : '',
            city: auth_user ? auth_user.city : '',
            pinCode: auth_user ? auth_user.pinCode : '',
            secEmail: auth_user ? auth_user.secEmail : '',
            secMobile: auth_user ? auth_user.secMobile : '',
        };
    }

    handleProfileUpdate = (e) => {
        e.preventDefault();

        const authUser = this.props.auth_user;
        var userStatus = authUser ? authUser.status : 0;

        // console.log('User status', userStatus);

        swal({
            title: "Are you sure",
            text: "To update your profile?",
            buttons: ["CANCEL", "UPDATE"],
        })
        .then((willUpdate) => {
            if (willUpdate) {

                this.props.onLoadingTrue(); // Start loader

                // Update profile
                var formData = new FormData(document.getElementById('update_profile_form'));

                // Api service
                Server.post(ApiPaths.UPDATE_PROFILE, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {

                        // Update user status
                        if(userStatus < 2) {
                            data.data.status = 2; // Update user status;
                            // localStorage.setItem('auth_user', JSON.stringify(data.data));
                        }

                        localStorage.setItem('auth_user', JSON.stringify(data.data));
                        this.props.onSetAuthUser(data.data);

                        // Update user token
                        if(data.data && data.data.accessToken && data.data.accessToken !== '') {
                            var token = data.data.accessToken;
                            localStorage.setItem('auth_token', token);
                            SetAuthToken(token); // Set auth user token
                        }

                        this.props.onDisplayMessage(data.message);
    
                        // Redirect to payment screen if user updated profile
                        if(data.data) {
                            if(userStatus < 2 && data.data.status === 2) {
                                setTimeout(function() {
                                    window.location.href = '/payment';
                                }, 1000);
                            }
                        }

                        // console.log(userStatus, data.data.status);
                    } else {
                        this.props.onDisplayError(data.message);
                    }
                })
                .catch(err => {
                    this.props.onDisplayError(err.message);
                })
    
                this.props.onLoadingFalse();
            }
        });
    }
    
    handleDateChange = (date) => {
        if (date !== ' ' && date !== undefined) {
            var today = moment(new Date());
            var birthDate = moment(new Date(date));

            var diffYears = today.diff(birthDate, 'years');

            this.setState({age: diffYears});
        }

        this.setState({dob: date});
    }

    render() {
        const {firstName, lastName, email, mobile, dob, age, clazz, institution, fatherName, fatherOccupation, motherName, motherOccupation, address1, address2, address3, city, pinCode, secEmail, secMobile} = this.state;
        const {auth_user} = this.props;

        return (
            <div className="container py-3 px-4">
                <form id="update_profile_form" onSubmit={(e) => this.handleProfileUpdate(e)}>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">First Name *</p>
                                <input type="text" name="firstName" className="form-control" placeholder="First Name" value={firstName} onChange={(e) => this.setState({firstName: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Last Name *</p>
                                <input type="text" name="lastName" className="form-control" placeholder="Last Name" value={lastName} onChange={(e) => this.setState({lastName: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Date of birth *</p>
                                <input type="date" name="dob" className="form-control" placeholder="Date of birth" min={'1995-01-01'} max={'2016-12-31'} value={dob} onChange={(e) => this.handleDateChange(e.target.value)} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Age in years</p>
                                <input type="text" disabled className="form-control" value={age && age !== '' ? (age + ' years')  : '0'} />
                                <input type="hidden" name="age" value={age} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Email ID *</p>
                                <input type="email" disabled className="form-control" placeholder="Email ID" value={email} required />
                                <input type="hidden" name="email" defaultValue={email} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Moblie Number *</p>
                                <input type="number" minLength={10} maxLength={10} name="mobile" className="form-control" placeholder="Mobile Number" value={mobile} onChange={(e) => this.setState({mobile: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Institution *</p>
                                <input type="text" name="institution" className="form-control" placeholder="Institution" value={institution} onChange={(e) => this.setState({institution: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Class *</p>
                                <input type="text" name="clazz" className="form-control" placeholder="Class" value={clazz} onChange={(e) => this.setState({clazz: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Father Name *</p>
                                <input type="text" name="fatherName" className="form-control" placeholder="Father Name" value={fatherName} onChange={(e) => this.setState({fatherName: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Father Occupation *</p>
                                <input type="text" name="fatherOccupation" className="form-control" placeholder="Father Occupation" value={fatherOccupation} onChange={(e) => this.setState({fatherOccupation: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Mother Name *</p>
                                <input type="text" name="motherName" className="form-control" placeholder="Mother Name" value={motherName} onChange={(e) => this.setState({motherName: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Mother Occupation *</p>
                                <input type="text" name="motherOccupation" className="form-control" placeholder="Mother Occupation" value={motherOccupation} onChange={(e) => this.setState({motherOccupation: e.target.value})} required />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Address 1</p>
                                <textarea rows={4} name="address1" className="form-control" placeholder="Address 1" value={address1} onChange={(e) => this.setState({address1: e.target.value})}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Address 2</p>
                                <textarea rows={4} name="address2" className="form-control" placeholder="Address 2" value={address2} onChange={(e) => this.setState({address2: e.target.value})}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Address 3</p>
                                <textarea rows={4} name="address3" className="form-control" placeholder="Address 3" value={address3} onChange={(e) => this.setState({address3: e.target.value})}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">City</p>
                                <input type="text" name="city" className="form-control" placeholder="City" value={city} onChange={(e) => this.setState({city: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Pin Code</p>
                                <input type="text" name="pinCode" className="form-control" placeholder="Pin Code" value={pinCode} onChange={(e) => this.setState({pinCode: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Secondary Email ID</p>
                                <input type="email" name="secEmail" className="form-control" placeholder="Secondary Email ID" value={secEmail} onChange={(e) => this.setState({secEmail: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <p className="labelText">Secondary Mobile</p>
                                <input type="number" name="secMobile" className="form-control" placeholder="Secondary Mobile" value={secMobile} onChange={(e) => this.setState({secMobile: e.target.value})} />
                            </div>
                        </div>
                    </div>

                    <input type="hidden" name="id" defaultValue={auth_user && auth_user.id} />

                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 offset-md-4">
                            <button type="submit" className="btn btn-primary btn-lg px-5">{'UPDATE'}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        auth_user: state.home.auth_user
    }
}

const mapActionsToProps = {
    onLoadingTrue: mainLoadingTrue,
    onLoadingFalse: mainLoadingFalse,
    onSetAuthUser: setAuthUser,
    onDisplayError: displayError,
    onDisplayMessage: displayMessage,
}

export default connect(mapStatesToProps, mapActionsToProps) (Profile);
