import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class BrainDrain extends React.Component {

    handleQuestionAnswer = (question, answer) => {
        var {userAnswers} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0) {
            userAnswers.splice(alreadyExistsIndex, 1);
        }

        // Add news answer for question
        userAnswers.push({question: question, answer: answer});
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);      

       // console.log("User answers", userAnswers)
    }

    renderTestView = () => {
        const {showQuestion, maxAnswers, userAnswers, questions} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questions[showQuestion].id);
        var answerSaved = 0;

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        return (
            <div className="text-left">
                <form>
                    <h6 className="questions font-weight-bold mb-2">
                        Q{showQuestion+1}. {questions[showQuestion].taskDesc}
                    </h6>

                    {/* Looping total answers */}
                    {maxAnswers.map((answer) => (
                        questions[showQuestion]["taskOption" + answer] && questions[showQuestion]["taskOption" + answer] !== null ? (
                            <div key={answer} className="form-check">
                                <input className="form-check-input" type="radio" onChange={() => this.handleQuestionAnswer(questions[showQuestion].id, answer)} value={answer} checked={(alreadyExistsIndex < 0 || answerSaved !== answer) ? false : true} />

                                <label className="form-check-label">
                                    {questions[showQuestion]["taskOption" + answer]}
                                </label>
                            </div>
                        ) 
                        : null
                        ))
                    }

                    {/* Show next or submit button based on question */}
                    {(questions.length - 1) !== showQuestion ? 
                        <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                    : 
                        <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                    }
                </form>
            </div>
        )
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default BrainDrain;