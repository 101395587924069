import { DISPLAY_MESSAGE, DISPLAY_ERROR, HIDE_MESSAGE, SET_AUTH_USER, LOGIN_SUCCESS, LOGOUT_SUCCESS, SUB_LOAD_TRUE, SUB_LOAD_FALSE, MAIN_LOAD_TRUE, MAIN_LOAD_FALSE, SUCCESS_STATUS } from '../actions/Home';

const initialState = {
    main_loader: false,
    sub_loader: false,
    is_auth: false,
    auth_user: {},
    message: '',
    error: '',
};

const Home_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case MAIN_LOAD_TRUE :
            return Object.assign({}, state, { main_loader: true });
        
        case MAIN_LOAD_FALSE :
            return Object.assign({}, state, { main_loader: false });

        case SUB_LOAD_TRUE :
            return Object.assign({}, state, { sub_loader: true });
    
        case SUB_LOAD_FALSE :
            return Object.assign({}, state, { sub_loader: false });

        case DISPLAY_MESSAGE :
            return Object.assign({}, state, { message: payload.message });
            
        case DISPLAY_ERROR :
            return Object.assign({}, state, { error: payload.error });

        case HIDE_MESSAGE :
            return Object.assign({}, state, { error: '', message: '' });

        case SET_AUTH_USER :
            return Object.assign({}, state, { auth_user: payload.auth_user, is_auth: true });

        case LOGIN_SUCCESS :
            return Object.assign({}, state, { is_auth: true });

        case LOGOUT_SUCCESS :
            return Object.assign({}, state, { auth_user: {}, is_auth: false });

        case SUCCESS_STATUS :
            return Object.assign({}, state, { status: payload.status });

        default :
            return state;
    }
}

export default Home_reducer;