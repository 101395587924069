import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { DEMO_USERS } from '../../../assets/constants';
import Images from '../../../assets/images';

class SideMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {title: 'Home'};
    }

    onMenuClick = (title) => {
        this.setState({title: title});
        this.props.onPageChange(title);
    }

    render() {
        const {auth_user} = this.props;

        return (
            <div className="sidebar shadow-lg p-3 mb-5 justify-content-center" style={{minHeight: '100%', boxShadow:' 4px 4px 20px #DADADA'}}>
                <div className="card MenuNavLinks p-5" style={{cursor: 'pointer'}} onClick={() => window.location.href = '/home'}>
                    <img className="img-fluid m-auto" src={Images.logo} alt="" />
                    {/* <h5 className="card-title mt-3">{auth_user.firstName}</h5> */}
                </div>
                <div className="mt-3">
                    <NavLink className="nav-link MenuNavLinks mb-3 bg-white rounded" activeClassName="link_active" exact to="/home" onClick={() => this.onMenuClick("Home")}> HOME </NavLink>
                    <NavLink className="nav-link MenuNavLinks mb-3 bg-white rounded" activeClassName="link_active" exact to="/edit-profile" onClick={() => this.onMenuClick("Edit Profile")}> EDIT PROFILE </NavLink>
                    {(auth_user && auth_user.status === 2) && <NavLink className="nav-link MenuNavLinks mb-3 bg-white rounded" activeClassName="link_active" exact to="/payment" onClick={() => this.onMenuClick("Subscription")}> SUBSCRIPTION </NavLink>}
                    {(auth_user && auth_user.status === 3) && <NavLink className="nav-link MenuNavLinks mb-3 bg-white rounded" activeClassName="link_active" exact to="/task-days" onClick={() => this.onMenuClick("User Tasks")}> TASKS </NavLink>}

                    {(auth_user && DEMO_USERS.includes(auth_user.id)) && <NavLink className="nav-link MenuNavLinks mb-3 bg-white rounded" activeClassName="link_active" exact to="/task-days/demo" onClick={() => this.onMenuClick("Demo Tasks")}> DEMO TASKS </NavLink>}
                    {/* <NavLink className="nav-link MenuNavLinks mb-3 bg-white rounded" activeClassName="link_active" exact to="/payment" onClick={() => this.onMenuClick("Payment")}> PAYMENT </NavLink>
                    <NavLink className="nav-link MenuNavLinks mb-3 bg-white rounded" activeClassName="link_active" exact to="/task-days" onClick={() => this.onMenuClick("User Tasks")}> TASKS </NavLink> */}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        auth_user: state.home.auth_user
    }
}

export default connect(mapStatesToProps, null) (SideMenu)
