import {Server, ApiPaths} from '../../utils/Server';
import {mainLoadingTrue, mainLoadingFalse, displayMessage, displayError} from './Home';

export const GET_TASK_GROUPS = 'GET_TASK_GROUPS';
export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_TASK_QUESTIONS = 'GET_TASK_QUESTIONS';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';

export const getTaskGroups = (formData) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post(ApiPaths.GET_TASK_GROUPS, formData)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statusCode === 200)
                    dispatch(getTaskGroupsSuccess(data.data));
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError(err.message));
            })
    }
}

export const getTaskDetails = (taskID) => {
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return Server.get(ApiPaths.GET_TASK_DETAILS + taskID)
            .then(response => {
                const { data } = response;
                // dispatch(mainLoadingFalse());
                if(data.statusCode === 200)
                    dispatch(getTaskDetailsSuccess(data.data));
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                // dispatch(mainLoadingFalse());
                dispatch(displayError(err.message));
            })
    }
}

export const getTaskQuestions = (formData) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post(ApiPaths.GET_TASK_QUESTIONS, formData)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statusCode === 200)
                    dispatch(getTaskQuestionsSuccess(data.data));
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError(err.message));
            })
    }
}

export const submitTaskAnswers = (userID, formData) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.post(ApiPaths.SUBMIT_ANSWERS + userID, formData)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(data.statusCode === 200)
                    dispatch(displayMessage(data.message));
                else
                    dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError(err.message));
            })
    }
}

export const getSubscriptionPlans = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return Server.get(ApiPaths.GET_TASK_PLANS)
        .then(response => {
            dispatch(mainLoadingFalse());
            const {data} = response;
            if(data.statusCode === 200)
                dispatch(getPlansSuccess(data.data));
            else
                dispatch(displayError(data.message));
        })
        .catch(error => {
            dispatch(mainLoadingFalse());
            dispatch(displayError('Failed, try again later'));
        });
    }
}

export const getTaskGroupsSuccess = (data) => {
    return {
        type : GET_TASK_GROUPS,
        payload: {
            groups: data
        }
    }
}

export const getTaskDetailsSuccess = (data) => {
    return {
        type : GET_TASK_DETAILS,
        payload: {
            taskData: data
        }
    }
}

export const getTaskQuestionsSuccess = (data) => {
    return {
        type : GET_TASK_QUESTIONS,
        payload : {
            questions : data
        }
    }
}

export const getPlansSuccess = (plans) => {
    return {
        type: GET_PLANS_SUCCESS,
        payload: {
            plans: plans,
        }
    }
}
