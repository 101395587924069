import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import Home_reducer from '../reducers/Home';
import Task_reducer from '../reducers/Tasks';

const All_reducers = combineReducers({
    home: Home_reducer,
    task: Task_reducer,
});
  
const store = createStore(All_reducers, applyMiddleware(thunk));

export default store;
