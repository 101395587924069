import React from 'react';
import {Button} from '@material-ui/core';
import Timer from '../../../common/timer';
import swal from 'sweetalert';
import ReactPlayer from 'react-player';
import ProgressBar from 'react-progressbar';
import { STATE_KEYS } from '../../../../assets/constants';

class GuessWho extends React.Component {

    constructor(props) {
      super(props);
      this.state = {playVideo: false, playedVideo: 0, totalTime: 0, playedTime: 0, videoEnded: false};
    }

    handleQuestionAnswer = (e, question) => {
        e.preventDefault();
        var answer = e.target.value;
        // alert(answer);
        var {userAnswers} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0 || answer === '') {
            userAnswers.splice(alreadyExistsIndex, 1);
        }

        if(answer !== '') {
            userAnswers.push({question: question, answer: answer});
        }

        // Add new answer for question
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
       // console.log("User answers", userAnswers)
    }

    onVideoPlaying = (val) => {
        this.setState({playedVideo: (val.played * 100), playedTime: Math.round(val.playedSeconds / 60)});
        // alert(JSON.stringify(val));
    }

    onVideoDuration = (val) => {
        this.setState({totalTime: Math.round(val / 60)});
        // alert(JSON.stringify(val));
    }

    onVideoEnd = () => {
        swal({title: "Video ended", text: "You have 3 mintes to answer, your time starts now...", button: "START"})
        .then((value) => {
            this.setState({videoEnded: true});
        });
    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions, charactersAllowed, taskData} = this.props;
        const {playVideo, playedVideo, playedTime, totalTime, videoEnded} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = '';
        var charactersLeft = charactersAllowed;

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
            charactersLeft = (charactersAllowed - userAnswers[alreadyExistsIndex].answer.length);
        }

        return (
            <div className="mb-4 pb-2">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="text-left">
                            <h6 className="questions font-weight-bold">
                                Q{showQuestion+1}. {question.taskDesc}
                            </h6>
                            <div>
                                <ReactPlayer playing={playVideo} controls={false} onDuration={(val) => this.onVideoDuration(val)} onEnded={() => this.onVideoEnd()} onProgress={(val) => this.onVideoPlaying(val)} width={'100%'} url={question.videoPath} />
                            </div>
                            
                            <div>
                                <ProgressBar style={{borderRadius: '2px', width: '100%', margin: '10px 0px 0px', backgroundColor: 'lightgrey', fill: 'red'}} completed={playedVideo} />
                                <span style={{fontSize: '12px'}}>{Math.round(playedVideo) + ' %'}</span>
                            </div>

                            <div className="text-center mt-3">
                                <Button size={"large"} variant="contained" color="primary" disabled={playVideo} onClick={() => this.setState({playVideo: true})}>PLAY VIDEO</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        {videoEnded && <h4>Time Remaining: <Timer minutes={3} onTimesUp={() => this.props.handleTimesUp()}/></h4>}
                        <div className="form-group mt-4">
                            <textarea rows={15} className="form-control" name={"exampleRadios"} placeholder="Use me to make notes" />
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h6>Answer Box</h6>
                        <div className="form-group">
                            <textarea rows={8} maxLength={this.props.charactersAllowed} disabled={!videoEnded} className="form-control" name={"exampleRadios"} onChange={(e) => this.handleQuestionAnswer(e, question.id)} value={answerSaved} placeholder="Type your answer here" />
                            <span className="leftCharacters">Characters left: {charactersLeft}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default GuessWho;