import React from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import Images from '../../../../assets/images';
import QueryString from 'query-string';

class UserTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {params: QueryString.parse(props.location.search)};
    }

    handleOpenTask = (group) => {
        if(group && group.responseStatus === 0) {
            window.location.href = "/task/" + group.id + '/task-details/' + group.group_name.toLowerCase().replace(/[ ]/g, '-');
        } else if(group.responseStatus === 1) {
            alert("You already completed this quiz");
        } else if(group.responseStatus === 2) {
            alert("Please complete all the task of Day 1 and try again.");
        }
    }

    renderTasks = () => {
        const {params} = this.state;
        const {day1_tasks, day2_tasks} = this.props;
        var groupsData = (params.task_day === 1 || params.task_day === '1') ? day1_tasks : day2_tasks;
        
        if(groupsData.length > 0) {
            return (
                <div className="container mt-4">
                    <div className="row justify-content-center">
                        {
                            groupsData.map((group, index) => (
                                <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-xs-12 mb-5">
                                    <div className="row">
                                        <div className="col" />
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-xs-12">
                                            <div className="card dayCard" onClick={() => this.handleOpenTask(group)}>
                                                <div className="card-body">
                                                    <img src={Images.choose} className="taskImage" alt="" />
                                                    <h1 className="taskTitle">{group.group_name}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className="container">
                    <div className="row">
                        <div class="col mt-5 pt-5">
                            <img src={Images.choose} className="taskImage" alt="" />
                            <h1 className="noResults">NO TASKS AVAILABLE FOR DAY {params.task_day}</h1>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="container">
                <Link to="/task-days/demo" style={{color: 'black'}}>
                    <div className="backArrowContainer">
                        <FontAwesomeIcon icon={faArrowCircleLeft} style={{height: 26, width: 26}} />
                        <h6 className="backText">Back to Days</h6>
                    </div>
                </Link>

                {this.renderTasks()}
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        auth_user: state.home.auth_user,
        day1_tasks: state.task.day1_tasks,
        day2_tasks: state.task.day2_tasks,
    }
};

export default connect(mapStatesToProps, null) (UserTasks);
