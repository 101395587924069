import React from 'react';
import ReactPlayer from 'react-player';
import {PLAYER_CONFIG} from '../../../assets/constants/';

const VideoPlayer = props => {
    const {
        playing,
        url,
        height = 400,
        onPlay = () => {},
        onEnded = () => {},
    } = props;

    return (
        <div className="col">
            <ReactPlayer
                playing={playing}
                controls={true}
                width={'100%'}
                height={height}
                url={url}
                config={PLAYER_CONFIG}
                onPlay={() => onPlay()}
                onEnded={() => onEnded()}
            />
        </div>
    );
};

export default VideoPlayer;
