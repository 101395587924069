import React from 'react';
import queryString from 'query-string';
import {Button} from '@material-ui/core';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import {Server, ApiPaths} from '../../../../utils/Server';
import {mainLoadingTrue, mainLoadingFalse, displayError, displayMessage} from '../../../../redux/actions/Home';

class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {params: queryString.parse(props.location.search), message: ''};
    }

    handleGetNextTask = async (dayType) => {
        const {auth_user} = this.props;

        this.props.onLoadingTrue();

        var formData = new FormData();
        formData.append('userId', auth_user && auth_user.uid);
        formData.append('dayType', dayType);

        // Api service
        await Server.post(ApiPaths.GET_NEXT_TASK, formData)
            .then(response => {
                const { data } = response;
                if(data.statusCode === 200) {
                    const {taskGroupData} = data.data;

                    if(taskGroupData && Object.entries(taskGroupData).length > 0) {
                        // Redirect to next task
                        window.location.href = "/task/" + taskGroupData.id + '/task-details/' + taskGroupData.group_name.toLowerCase().replace(/[ ]/g, '-');
                    } else {
                        swal({
                            title: "Task not found",
                            text: "There is no task available right now, Please try again",
                            buttons: ["CANCEL", "RETRY"],
                        })
                        .then((willRetry) => {
                            if (willRetry) {
                                this.handleGetNextTask(dayType);
                            }
                        });
                    }

                    this.setState({message: ''});
                } else {
                    this.setState({message: data.message});
                    // this.props.onDisplayError(data.message);
                }
            })
            .catch(err => {
                this.props.onDisplayError(err.message);
            });

        this.props.onLoadingFalse();
    }

    render() {
        const {params, message} = this.state;

        return (
            <div className="row">
                <div className="col">
                    <div className="row m-4">
                        {/* <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 card p-3">
                            <div className="card-body">
                                <h3 className="card-title">Congratulations</h3>
                                <p className="card-text mt-4">You have successfully completed the test, Your answers are submitted, Our team will revoew your answeres and revert back to you with your score, Kindly complete all the remaining tests.</p>
                            </div>
                        </div> */}
                        <div className="col"/>
                        <div className="col-md-6 col-12 card p-2">
                            <div className="card-body">
                                <h4 className="card-title">Submission Status</h4>
                                <hr className="my-3" />
                                <h6 className="text-left">Time taken: <span className="activeView lightRadius px-2 py-1 ml-2 float-right">{params.minutes + ' : ' + ('0' + params.seconds).slice(-2)}</span></h6>
                                <hr className="my-3" />
                                <h6 className="text-left">Total questions: <span className="activeView lightRadius px-2 py-1 ml-2 float-right">{parseInt(params.answered) + parseInt(params.unanswered)}</span></h6>
                                <hr className="my-3" />
                                <h6 className="text-left">Answered questions: <span className="successView lightRadius px-2 py-1 ml-2 float-right">{params.answered}</span></h6>
                                <hr className="my-3" />
                                <h6 className="text-left">Unanswered questions: <span className="warningView lightRadius px-2 py-1 ml-2 float-right">{params.unanswered}</span></h6>
                                {/* <hr className="my-3" /> */}
                            </div>

                            {/* <h4>All the best</h4> */}
                        </div>
                        <div className="col"/>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            {(message && message !== '') && <h5 className="text-center">{message}</h5>}
                            <br/>
                            {(parseInt(params.weight) !== 1) ? (
                                <Button className="py-3 px-4" variant="contained" color="primary" onClick={() => this.handleGetNextTask(params.day)}>{'Start Next Task'}</Button>
                            ) : (
                                <div>
                                    {(parseInt(params.day) === 1) ? 
                                        <div>
                                            <h4 className="text-center"><i className="fa fa-check-circle text-success mr-2"></i>{'Congratulations...'}</h4>
                                            <h5 className="text-center mt-3">{'You have successfully completed all the tasks of day 1.'}</h5>
                                            <h5 className="text-center">{'Comeback after 24 hours from now to take up tasks of Day 2.'}</h5>
                                        </div>
                                    : 
                                        <div>
                                            <h4 className="text-center"><i className="fa fa-check-circle text-success mr-2"></i>{'Congratulations...'}</h4>
                                            <h5 className="text-center mt-3">{'You have successfully completed all the tasks.'}</h5>
                                            <h5 className="text-center">{'You will receive the assessment report to your registered mail id'}</h5>
                                            <h5 className="text-center">{'Contact us and book your slots for personal counselling'}</h5>
                                        </div>
                                    }
                                    
                                    <Button className="mt-4 py-3 px-4" variant="contained" color="primary" onClick={() => window.location.href = '/task-days'}>{'Go to tasks home'}</Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        auth_user: state.home.auth_user,
    }
}

const mapActionsToProps = {
    onLoadingTrue: mainLoadingTrue,
    onLoadingFalse: mainLoadingFalse,
    onDisplayError: displayError,
    onDisplayMessage: displayMessage,
};

export default connect(mapStatesToProps, mapActionsToProps) (Preview);