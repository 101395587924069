import React from 'react';
import {
    Route,
    BrowserRouter as Router,
    Redirect,
    Switch,
} from 'react-router-dom';
import PaymentScreen from '../components/dashboard/payment';
import Header from '../components/layout/header';
import Footer from '../components/layout/footer';
import SideMenu from '../components/layout/sideMenu';
import Home from '../components/dashboard/home';
import UserProfile from '../components/dashboard/userProfile';
import TaskDays from '../components/dashboard/games/userTasks/taskDays';
import UserTasks from '../components/dashboard/games/userTasks/userTasks';
import DemoTaskDays from '../components/dashboard/games/demoTasks/taskDays';
import DemoUserTasks from '../components/dashboard/games/demoTasks/userTasks';
import TaskHome from '../components/dashboard/games/userTasks/taskHome';
import BrainDrain from '../components/dashboard/games/braindrain/';
import Survey from '../components/dashboard/games/survey/';
import SituationBased from '../components/dashboard/games/situtationBased';
import Preview from '../components/dashboard/games/preview';
import InterQuiz from '../components/dashboard/games/interQuestionQuiz';
import IntraQuiz from '../components/dashboard/games/intraQuestionQuiz';
import Incognito from '../components/dashboard/games/incognitoQuiz';
import DimensionsQuiz from '../components/dashboard/games/dimensionsQuiz';
import MixerQuiz from '../components/dashboard/games/mixer';
import GuessWho from '../components/dashboard/games/guessWho';
import HandwritingQuiz from '../components/dashboard/games/handwritingQuiz';
import TempoChangeQuiz from '../components/dashboard/games/tempoChange';
import TwoTunes from '../components/dashboard/games/twoTunes';
import WebCamScreen from '../components/dashboard/webcam/';
import {connect} from 'react-redux';

class AuthRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {title: 'HOME', currentURL: window.location.href};
    }

    componentDidMount() {
        this.handleSplitURL(window.location.href);
    }

    componentDidUpdate() {
        if (window.location.href !== this.state.currentURL) {
            this.setState({currentURL: window.location.href});
            // console.log(window.location.href, this.state.currentURL);
            this.handleSplitURL(window.location.href);
        }
    }

    // Split URL to split route title
    handleSplitURL = URL => {
        var currentURL = URL.split('/');
        if (currentURL && currentURL.length > 0) {
            var routeTitle = currentURL[currentURL.length - 1].replace(
                /[-]/g,
                ' ',
            );

            // Split URL if parameters exists
            var routeKeys = routeTitle.split('?');
            if (routeKeys && routeKeys.length > 0) {
                routeTitle = routeKeys[0];
            }
            this.setState({title: routeTitle.slice('?')});
        }
    };

    onPageChange = title => {
        this.setState({title: title});
    };

    render() {
        const {title} = this.state;
        const {auth_user} = this.props;

        var redirectRoute = '/home';

        if (auth_user.status === 1) {
            redirectRoute = '/edit-profile';
        } else if (auth_user.status === 2) {
            redirectRoute = '/payment';
        } else if (auth_user.status === 3) {
            redirectRoute = '/task-days';
        }

        return (
            <Router>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-12 p-0">
                        <SideMenu onPageChange={this.onPageChange} />
                    </div>

                    <div className="col-lg-9 col-md-9 col-xs-12 px-3">
                        <Header title={title} />

                        <div className="MainContainer">
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to={redirectRoute} />
                                </Route>

                                <Route exact path="/home" component={Home} />
                                <Route
                                    exact
                                    path="/edit-profile"
                                    component={UserProfile}
                                />
                                <Route
                                    exact
                                    path="/payment"
                                    component={PaymentScreen}
                                />
                                <Route
                                    exact
                                    path="/task-days"
                                    component={TaskDays}
                                />
                                <Route
                                    exact
                                    path="/user-tasks"
                                    component={UserTasks}
                                />
                                <Route
                                    exact
                                    path="/task/:taskID/task-details/:taskName"
                                    component={TaskHome}
                                />
                                <Route
                                    exact
                                    path="/preview"
                                    component={Preview}
                                />

                                {/* Demo routes */}
                                <Route
                                    exact
                                    path="/task-days/demo"
                                    component={DemoTaskDays}
                                />
                                <Route
                                    exact
                                    path="/user-tasks/demo"
                                    component={DemoUserTasks}
                                />

                                <Route
                                    exact
                                    path="/brain_drain"
                                    component={BrainDrain}
                                />
                                <Route
                                    exact
                                    path="/survey"
                                    component={Survey}
                                />
                                <Route
                                    exact
                                    path="/situation_based"
                                    component={SituationBased}
                                />
                                <Route
                                    exact
                                    path="/inter"
                                    component={InterQuiz}
                                />
                                <Route
                                    exact
                                    path="/intra"
                                    component={IntraQuiz}
                                />
                                <Route
                                    exact
                                    path="/incognito"
                                    component={Incognito}
                                />
                                <Route
                                    exact
                                    path="/dimension"
                                    component={DimensionsQuiz}
                                />
                                <Route
                                    exact
                                    path="/mixer"
                                    component={MixerQuiz}
                                />
                                <Route
                                    exact
                                    path="/guess_who"
                                    component={GuessWho}
                                />
                                <Route
                                    exact
                                    path="/handwriting"
                                    component={HandwritingQuiz}
                                />
                                <Route
                                    exact
                                    path="/tempo_change"
                                    component={TempoChangeQuiz}
                                />
                                <Route
                                    exact
                                    path="/two_tunes"
                                    component={TwoTunes}
                                />
                                <Route
                                    exact
                                    path="/web-cam"
                                    component={WebCamScreen}
                                />
                            </Switch>
                        </div>

                        <Footer />
                    </div>
                </div>
            </Router>
        );
    }
}

const mapStatesToProps = state => {
    return {
        auth_user: state.home.auth_user,
        title: state.home.title,
    };
};

export default connect(mapStatesToProps, null)(AuthRoutes);
