import React from 'react';
import {Button} from '@material-ui/core';
import AudioPlayer from '../../../common/player/AudioPlayer';
import { STATE_KEYS } from '../../../../assets/constants';

class TwoTunes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {playAudio: [false, false, false, false, false]};
    }

    componentDidUpdate(oldProps, newProps) {
        // Stop all audios when question changes
        if(oldProps.showQuestion !== this.props.showQuestion) {
            this.setState({playAudio: [false, false, false, false, false]});
        }
    }

    handleQuestionAnswer = (question, answer) => {
        let existedAnswers = [];
        var {userAnswers} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0) {
            existedAnswers = userAnswers[alreadyExistsIndex].answer;

            // Remove first answer if it already have 2 answers and current answer does not exists in already answered
            if(existedAnswers && existedAnswers.length > 1 && (existedAnswers.includes(answer) === false)) {
                existedAnswers.shift();  // Remove first answer if already 2 answers exists
            }

            // Push answer if it's already not in array
            if(existedAnswers.includes(answer) === false) {
                existedAnswers.push(answer); // Push answer to array
            }

            userAnswers[alreadyExistsIndex] = {question: question, answer: existedAnswers};
        } else {
            existedAnswers.push(answer); // Push answer to array
            userAnswers.push({question: question, answer: existedAnswers});
        }

        // Add new answer for question
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);      

        // console.log("User answers", userAnswers);
    }

    onAudioPlay = (index) => {
        var playAudio = [false, false, false, false, false];
        playAudio[index] = true;
        this.setState({playAudio: playAudio});
    }

    onAudioEnded = () => {
        // 
    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions, maxAnswers} = this.props;
        var {playAudio} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questions[showQuestion].id);
        var answerSaved = [];

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        return (
            <div>
                <div className="row">
                    <div className="col">
                        <h6>Original audio</h6>
                        <AudioPlayer type={1} playing={playAudio[0]} disabled={false} url={question.taskDesc} onPlay={() => this.onAudioPlay(0)} onEnded={() => this.onAudioEnded()}  />
                    </div>
                </div>

                {maxAnswers.map((answer, index) => (
                    (question["taskOption" + answer] && question["taskOption" + answer] !== null) && (
                        <div key={answer} className="my-4 text-left">
                            <h6 className="ml-4">
                                <input className="form-check-input" type="checkbox" onChange={() => this.handleQuestionAnswer(question.id, answer)} value={answer} checked={(alreadyExistsIndex >= 0 && answerSaved.includes(answer))} />
                                <span>Tune {index+1}</span>
                            </h6>

                            <AudioPlayer type={1} playing={playAudio[(index + 1)]} disabled={false} url={question["taskOption" + answer]} onPlay={() => this.onAudioPlay(index + 1)} onEnded={() => this.onAudioEnded()} />
                        </div>
                    )
                ))}

                {/* Show next or submit button based on question */}
                <div className="align-center my-5">
                    {(questions.length - 1) !== showQuestion ? 
                        <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                    : 
                        <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                    }
                </div>
            </div>
        )
    }
      
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default TwoTunes;