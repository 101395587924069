import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class IncognitoQuiz extends React.Component {

    handleQuestionAnswer = (e, question) => {
      e.preventDefault();
      var answer = e.target.value;
      // alert(answer);
      var {userAnswers} = this.props;
      var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

      // remove answer if alreay exists
      if(alreadyExistsIndex >= 0 || answer === '') {
        userAnswers.splice(alreadyExistsIndex, 1);
      }

      if(answer !== '') {
        userAnswers.push({question: question, answer: answer});
      }

      // Add new answer for question
      this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);      

      // console.log("User answers", userAnswers);
    }

    renderTestView = () => {
        const {showQuestion, questions, userAnswers, charactersAllowed} = this.props;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = '';
        var charactersLeft = charactersAllowed;

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
            charactersLeft = (charactersAllowed - userAnswers[alreadyExistsIndex].answer.length);
        }

        return (
            <div className="mb-4 pb-2">
                <div className="text-left">
                    <h6 className="questions font-weight-bold">
                        Q{showQuestion+1}. {question.taskDesc}
                    </h6>
                    <div className="form-group">
                        <textarea rows={3} maxLength={this.props.charactersAllowed} className="form-control" name={"exampleRadios"} onChange={(e) => this.handleQuestionAnswer(e, question.id)} value={answerSaved} placeholder="Type your answer here" />
                        <span className="leftCharacters">Characters left: {charactersLeft}</span>
                    </div>
                </div>

                {/* Show next or submit button based on question */}
                <div className="align-center mx-auto mb-4">
                    {(questions.length - 1) !== showQuestion ? 
                        <Button className="float-right" size={"large"} variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                    : 
                        <Button className="float-right" size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default IncognitoQuiz;