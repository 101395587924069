import React from 'react';
import {Button} from '@material-ui/core';
import Images from '../../../../assets/images';
import { STATE_KEYS } from '../../../../assets/constants';

class WhatNext extends React.Component {
    
    handleQuestionAnswer = (questionID, answer) => {

        var {userAnswers, taskTime} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questionID);

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0) {
            userAnswers.splice(alreadyExistsIndex, 1);
        }

        // Add news answer for question
        userAnswers.push({question: questionID, answer: answer, timeTaken: taskTime});

        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
        this.props.handleSetState(STATE_KEYS.ERROR, '');

        // console.log("User answers", userAnswers)
    }

    handleDragStart = (e) => {
        e.dataTransfer.setData("imageUrl", e.target.src);
    }

    handleDragOver = (e) => {
        e.preventDefault();
    }

    handleDrop = (e, questionID, posVal, droppedImages) => {
        e.preventDefault();
        var imageUrl = e.dataTransfer.getData("imageUrl");
        droppedImages[posVal] = imageUrl;
        
        // Save answer
        this.handleQuestionAnswer(questionID, droppedImages);
    }

    handleRemoveImage = (questionID, posVal, droppedImages) => {
        droppedImages[posVal] = '';

        // Save answer
        this.handleQuestionAnswer(questionID, droppedImages);
    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions, error} = this.props;
        var question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var subQuestions = [];

        if((parseInt(question.catId) === 26) || (parseInt(question.catId) === 33)) {
            var dropPositions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
            var droppedImages = ['','','','','','','','','','','','','','','','','',''];
            var answerSaved = [];
            var totalColumns = 3;
            var totalRows = 6;
            var bgColor = "#C13FBD";

            if(alreadyExistsIndex >= 0) {
                answerSaved = userAnswers[alreadyExistsIndex].answer;
                droppedImages = answerSaved; // Saved answers
            }

            if(question.taskOption1 !== '') {
                subQuestions = question.taskOption1.split('||');
            }

            // Total columns
            if(question.taskOption2 !== '') {
                totalColumns = parseInt(question.taskOption2);
            }

            // Total rows
            if(question.taskOption3 !== '') {
                totalRows = parseInt(question.taskOption3);
            }

            // background color
            if(question.taskOption4 !== '') {
                bgColor = question.taskOption4;
            }

            // Reset number of positions
            if(totalColumns > 0 && totalRows > 0 && droppedImages.length > 0) {
                dropPositions.length = (totalColumns * totalRows) === 1 ? 2 : (totalColumns * totalRows);

                // Convet to array if it is stringified
                if(Array.isArray(droppedImages)) {
                    droppedImages.length = (totalColumns * totalRows) === 1 ? 2 : (totalColumns * totalRows);
                } else {
                    droppedImages = JSON.parse(droppedImages);
                    droppedImages.length = (totalColumns * totalRows) === 1 ? 2 : (totalColumns * totalRows);
                }
            }
    
            return (
                <div>
                    <h5 className="font-weight-bold mt-2">
                        Drag and drop the given options to make next image
                    </h5>

                    <div className="row">
                        <div className="col" />
                        <div className="col-md-8 col-12">
                            <img src={question.taskDesc} className="img-fluid" alt="" />
                        </div>
                        <div className="col" />
                    </div>
                    <hr/>

                    <div className="row">
                        <div className="col p-4">
                            <h6 className="font-weight-bold">Image options(Use these to create new image)</h6>
                            
                            <div className="row imageDropContainer mt-5" style={{backgroundColor: bgColor}}>
                                {subQuestions.map((subQuestion, index) => (
                                    <div key={index} className="col-md-4 col-4 mb-4">
                                        <img src={subQuestion} id={"draggable_image_index"} draggable className="draggable img-fluid" onDragStart = {(e) => this.handleDragStart(e)} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col p-4">
                            <h6 className="font-weight-bold">Drag and drop option images in a given space here to form new image</h6>
                            <div className="row imageDropContainer justify-content-centre" style={{backgroundColor: bgColor}}>
                                {dropPositions.length === 2 ?
                                    <div>
                                        <h6 className="text-light">{'Answer'}</h6>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <div className="imageDropBackgroundView">
                                                    {(droppedImages.length > 0 && droppedImages[0] !== '') && (
                                                        <img src={droppedImages[0]} className="droppedBackgroundImage img-fluid" alt="" />
                                                    )}

                                                    <div className="imageDropOverlapView">
                                                        {(droppedImages.length > 0 && droppedImages[1] !== '') && (
                                                            <img src={droppedImages[1]} className="droppedOverlapImage img-fluid" alt="" />
                                                        )}
                                                    </div>        
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            {
                                                dropPositions.map((pos, index) => (
                                                    <div key={index} className="col mb-3">
                                                        <h6 className="text-light">{index === 0 ? 'Drop background image' : 'Drop foreground image'} in below box</h6>
                                                        <div className="droppable imageDropView" onDragOver={(e) => this.handleDragOver(e)} onDrop={(e) => this.handleDrop(e, question.id, pos, droppedImages)}>
                                                            {(droppedImages.length > 0 && droppedImages[pos] !== '') && (
                                                                <div className="col">
                                                                    <img src={droppedImages[pos]} className="droppedImage img-fluid" alt="" />
                                                                    <img src={Images.deleteWhite} className="removeImageIcon mt-3" onClick={() => this.handleRemoveImage(question.id, pos, droppedImages)} alt="" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                :
                                    dropPositions.map((pos, index) => (
                                        <div key={index} className={totalColumns === 3 ? "col-md-4 col-4 mb-3" : "col-md-6 col-6 mb-3"}>
                                            <div className="droppable imageDropView" onDragOver={(e) => this.handleDragOver(e)} onDrop={(e) => this.handleDrop(e, question.id, pos, droppedImages)}>
                                                {(droppedImages.length > 0 && droppedImages[pos] !== '') && (
                                                    <div>
                                                        <img src={droppedImages[pos]} className="droppedImage img-fluid" alt="" />
                                                        <img src={Images.deleteWhite} className="removeImageIcon" onClick={() => this.handleRemoveImage(question.id, pos, droppedImages)} alt="" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            
                    <div className="text-center">
                        <span className="errorMessage">{error}</span>
                    </div>
                    
                    {/* Show next or submit button based on question */}
                    {(questions.length - 1) !== showQuestion ? 
                        <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                    : 
                        <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                    }
                </div>
            )
        } else if((parseInt(question.catId) === 32)) {
            var answerSaved = '';

            if(alreadyExistsIndex >= 0) {
                answerSaved = userAnswers[alreadyExistsIndex].answer;
            }

            if(question.taskOption1 !== '') {
                subQuestions = question.taskOption1.split('||');
            }

            return (
                <div>
                    <h5 className="font-weight-bold mt-2">
                        Select any of the image from the following options which will complete the image
                    </h5>

                    <div className="row">
                        <div className="col" />
                        <div className="col">
                            <img src={question.taskDesc} className="img-fluid" alt="" />
                        </div>
                        <div className="col" />
                    </div>
                    <hr/>

                    <div className="row mt-2">
                        <div className="col p-2">                            
                            <div className="row mt-4">
                                {subQuestions.map((subQuestion, index) => (
                                    <div key={index} className="col-md-3 col-3 mb-4">
                                        <h6 className="ml-4">
                                            <input className="form-check-input" type="radio" checked={answerSaved === subQuestion} onChange={() => this.handleQuestionAnswer(question.id, subQuestion)} />
                                            <span>Image {index+1}</span>
                                        </h6>
                                        <img src={subQuestion} className="img-fluid" alt="" onChange={() => this.handleQuestionAnswer(question.id, subQuestion)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="text-center">
                        <span className="errorMessage">{error}</span>
                    </div>
                    
                    {/* Show next or submit button based on question */}
                    {(questions.length - 1) !== showQuestion ? 
                        <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                    : 
                        <Button className="float-right" variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                    }
                </div>
            )
        }
    }
      
    render() {

        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default WhatNext;