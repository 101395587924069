import axios from "axios";

export const BaseURL = "https://api.jagrati.reduxcel.com/";

export const IconsPath =
  "https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/task_group_icons/";

export const Server = axios.create({
  baseURL: BaseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    Partner: "Jagrati",
    Source: "User",
  },
});

export const SetAuthToken = (token) => {
  if (token) {
    Server.defaults.headers.common.Token = token;
  } else {
    delete Server.defaults.headers.common.Token;
  }
};

export const ApiPaths = {
  REGISTER: "user/add",
  LOGIN: "user/login",
  SENT_OTP: "user/sendLoginOtp",
  VERIFY_OTP: "user/verifyLoginOtp",
  UPDATE_PROFILE: "user/updateUserDetails",
  GET_DAY_STATUS: "task/getDayDone/", // User UID
  GET_TASK_GROUPS: "task/taskGroupForUser",
  GET_NEXT_TASK: "task/getNextTaskGroupForUser",
  GET_TASK_DETAILS: "task/getTaskGroupDetailsById/",
  GET_TASK_QUESTIONS: "task/taskForUser",
  SUBMIT_ANSWERS: "task/submitTaskGroupResponse/",
  UPLOAD_FILE: "util/file/upload",
  GET_TASK_PLANS: "task/getTaskPlans",
  CREATE_ORDER: "payment/createOrder",
  PAYMENT_SUCCESS: "payment/onPaymentSuccess/",
  UPDATE_USER_PLAN: "user/updateUserPlan",
};
