import React, { Component } from 'react';

export default class Timer extends Component {
    
    constructor(props) {
        super(props);
        this.state = {minutes: props.minutes, seconds: props.seconds ? props.seconds : 0, currentTime: {minutes: 0, seconds: 0}};
    }

    componentDidMount() {
        if(this.props.start) {
            this.myInterval = setInterval(() => {
                const { seconds, minutes, currentTime } = this.state

                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this.myInterval)
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                    }
                } 

                // Fetch current time completed
                if (currentTime.seconds < 59) {
                    this.setState({currentTime: {
                        minutes: currentTime.minutes,
                        seconds: currentTime.seconds + 1
                    }});
                }
                if (currentTime.seconds === 59) {
                    this.setState({currentTime: {
                        minutes: currentTime.minutes + 1,
                        seconds: 0
                    }});
                } 

            }, 1000)
        }
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { minutes, seconds, currentTime } = this.state

        if(minutes === 0 && seconds === 0) {
            this.props.onTimesUp();
        } else {
            // this.props.handleCurrentTime(currentTime);
        }

        return (
            <span>
                { minutes === 0 && seconds === 0
                    ? <span>Time UP!</span>
                    : <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                }
            </span>
        )
    }
}