import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class InterQuestion extends React.Component {

    handleQuestionAnswer = (question, answer) => {
        var {userAnswers} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0) {
            userAnswers.splice(alreadyExistsIndex, 1);
        }

        // Add news answer for question
        userAnswers.push({question: question, answer: answer});
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);

       // console.log("User answers", userAnswers)
    }

    renderTestView = (question, index) => {
        const {maxAnswers, userAnswers} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answerSaved = 0;

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        return (
            <div key={index} className="mb-4 pb-2" style={{borderBottomColor: 'lightgrey', borderBottomWidth: 1, borderBottomStyle: 'solid'}}>
                <div className="text-left">
                    <form>
                        <h6 className="questions font-weight-bold">
                            Q{index+1}. {question.taskDesc}
                        </h6>

                        {/* Looping total answers */}
                        {maxAnswers.map((answer) => (
                            (question["taskOption" + answer] && question["taskOption" + answer] !== null) && (
                                <div key={answer} className="form-check d-inline mr-4">
                                    <input className="form-check-input" type="radio" onChange={() => this.handleQuestionAnswer(question.id, answer)} value={answer} checked={(alreadyExistsIndex < 0 || answerSaved !== answer) ? false : true} />
                                
                                    <label className="form-check-label">
                                        {question["taskOption" + answer]}
                                    </label>
                                </div>
                            )
                        ))}
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const {questions} = this.props;
        
        return (
            <div className="card">
                <div className="card-body">
                    {questions.map((question, index) => (
                        this.renderTestView(question, index)
                    ))}
                </div>

                <div className="align-center mx-auto mb-4">
                    <Button className="float-right px-5" size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                </div>
            </div>
        );
    }
}

export default InterQuestion;