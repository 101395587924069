import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import Images from '../../../../assets/images';
import {getTaskGroups} from '../../../../redux/actions/Tasks';

class TaskDays extends React.Component {

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        const {auth_user} = this.props;
        var formData = new FormData();
        formData.append('userId', auth_user.uid);
        this.props.onGetTaskGroups(formData); // api call
    }

    render() {
        return (
            <div className="container">
                <div className="row mt-5">
                    <div className="col" />
                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                        <Link to={"/user-tasks/demo?task_day=" + 1}>
                            <div className="card dayCard">
                                <div className="card-body">
                                    <img src={Images.day} className="dayImage" alt="" />
                                    <h1 className="dayTitle">DAY 1</h1>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col" />
                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                        <Link to={"/user-tasks/demo?task_day=" + 2}>
                            <div className="card dayCard">
                                <div className="card-body">
                                    <img src={Images.day} className="dayImage" alt="" />
                                    <h1 className="dayTitle">DAY 2</h1>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col" />
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        auth_user: state.home.auth_user,
    }
}

const mapActionsToProps = {
    onGetTaskGroups: getTaskGroups
};

export default connect(mapStatesToProps, mapActionsToProps) (TaskDays);

