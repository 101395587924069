import React from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Link } from 'react-router-dom';
import { Container, Avatar, Button, CssBaseline, TextField, Typography} from '@material-ui/core';
import '../../App.css';
import '../../Responsive.css';
import { connect } from 'react-redux';
import {mainLoadingTrue, mainLoadingFalse, loginSuccess, setAuthUser, displayError, displayMessage} from '../../redux/actions/Home';
import {Server, ApiPaths, SetAuthToken} from '../../utils/Server';
import queryString from 'query-string';

class SignIn extends React.Component {

    constructor(props) {
        super(props);
        const params = queryString.parse(this.props.location.search);
        this.state = {message: '', error: '', email: params.email ? params.email : '', otp: '', acceptTerms: (params.accept_terms && parseInt(params.accept_terms) === 1) ? true : false, otpSent: false};
    }

    handleSendOTP = async () => {    
        const {email, acceptTerms} = this.state;

        var emailRegExp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

        if(!emailRegExp.test(email)){
            this.props.onDisplayError('Invalid Email ID');
        } else if (!acceptTerms) {
            this.props.onDisplayError('Please read and accept terms and condition to proceed');
        } else {
            this.props.onDisplayError('');

            this.props.onLoadingTrue();

            // Form Data
            var formData = new FormData();
            formData.append('email', email);

            // Api service
            await Server.post(ApiPaths.SENT_OTP, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({otpSent: true});
                        this.props.onDisplayMessage('OTP has been sent to your email id');
                    } else {
                    this.props.onDisplayError(data.message);
                    }
                })
                .catch(err => {
                    this.props.onDisplayError(err.message);
                });

                this.props.onLoadingFalse();
        }
    }

    handleVerifyOTP = async () => {
        const {email, otp} = this.state;
        
        if(otp.length !== 6) {
            this.props.onDisplayError('OTP must have 6 digits');
        } else {
            this.props.onDisplayError('');

            this.props.onLoadingTrue();

            // Create form data
            var formData = new FormData();
            formData.append('email', email);
            formData.append('otp', otp);

            // Api service
            await Server.post(ApiPaths.VERIFY_OTP, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        localStorage.setItem('auth_user', JSON.stringify(data.data));
                        var token = data.data.accessToken;
                        localStorage.setItem('auth_token', token);
                        SetAuthToken(token);
                        this.props.onLoginSuccess();
                        this.props.onSetAuthUser(data.data);

                        this.setState({otpSent: false});
                        this.props.onDisplayMessage(data.message);

                        window.location.href = '/'; // Redirect to dashboard
                    } else {
                        this.props.onDisplayError(data.message);
                    }
                })
                .catch(err => {
                    this.props.onDisplayError(err.message);
                })

                this.props.onLoadingFalse();
        }
    }

    render() {
        const {email, otp, acceptTerms, otpSent} = this.state;
        const {error, message} = this.props;
        
        return (
            <Container component="main" className="BgImage" maxWidth="lg" xs={12} lg={4} md={4}>
                <CssBaseline />
                <Container className="paper" component="main" maxWidth="xs" xs={12} lg={4} md={4}>
                <Avatar className="avatar">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                    <form className="form" noValidate>
                        <TextField
                            value={email}
                            onChange={(e) => this.setState({email: e.target.value})}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            placeholder="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            disabled={otpSent}
                        />

                        {otpSent && 
                            <TextField
                                value={otp}
                                onChange={(e) => this.setState({otp: e.target.value})}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                name="otp"
                                placeholder="Enter 6 digit OTP"
                                type="number"
                                id="otp"
                                autoComplete="current-otp"
                            />
                        }
                    
                        <div className="my-3">
                            <input type="checkbox" name="accept_terms" checked={acceptTerms} onChange={() => this.setState({acceptTerms: !acceptTerms})} />
                            <Link to={'/terms-conditions?email=' + email}>
                                <span className="ml-2 mt-0">I have read and accept terms and conditions</span>
                            </Link>
                        </div>

                        {error && error !== '' ? <p className="errorMessage">{error}</p> : null}
                        {message && message !== '' ? <p className="messageText">{message}</p> : null}

                        {otpSent ? 
                            <div>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="submit"
                                    onClick={() => this.handleVerifyOTP()}
                                >
                                    VERIFY OTP
                                </Button>

                                <div className="mt-4" />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="submit"
                                    onClick={() => this.handleSendOTP()}
                                >
                                    Didn't recieve OTP?, RESEND
                                </Button>
                            </div>
                        :
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="submit"
                                onClick={() => this.handleSendOTP()}
                            >
                                SEND OTP
                            </Button>
                        }
                    </form>
                </Container>
            </Container>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        error: state.home.error,
        message: state.home.message,
    }
}

const mapActionsToProps = {
    onLoadingTrue: mainLoadingTrue,
    onLoadingFalse: mainLoadingFalse,
    onLoginSuccess: loginSuccess,
    onSetAuthUser: setAuthUser,
    onDisplayError: displayError,
    onDisplayMessage: displayMessage,
}

export default connect(mapStatesToProps, mapActionsToProps) (SignIn);