import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class Cognizant extends React.Component {

    constructor(props) {
        super(props);
        this.state = {error: ''};
    }

    handleQuestionAnswer = (question, subQuestion) => {
        var titleValue = document.getElementById('answer_title_' + question).value;
        // "answer_description_" + question.id + '_' + subQuestion
        var descriptionValue1 = document.getElementById('answer_description_' + question + '_1').value;
        var descriptionValue2 = document.getElementById('answer_description_' + question + '_2').value;
        var descriptionValue3 = document.getElementById('answer_description_' + question + '_3').value;

        var regExp = new RegExp(/^[a-zA-Z ]*$/);

        if(regExp.test(titleValue)) {
            this.setState({error: ''});

            var {userAnswers} = this.props;
            var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

            // Remove answer if alreay exists
            if(alreadyExistsIndex >= 0 || titleValue === '') {
                userAnswers.splice(alreadyExistsIndex, 1);
            }

            // Push new answer
            if(titleValue !== '' || descriptionValue1 !== '' || descriptionValue2 !== '' || descriptionValue3 !== '') {
                userAnswers.push({question: question, answer: (titleValue + '||' + descriptionValue1 + '||' + descriptionValue2 + '||' + descriptionValue3)});
            }

            // Add new answer for question
            this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
        } else {
            this.setState({error: 'Only characters are allowed'});
        }

        // console.log("User answers", userAnswers);
    }

    renderTestView = (question, index, showQuestion) => {
        return (
            <div key={index} className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12" onClick={() => {
                this.setState({error: ''});
                this.props.handleSetState(STATE_KEYS.QUESTION, index)}
            }>
                <img src={question.taskDesc} className={(showQuestion === index) ? "nameNarrateImage img-thumbnail bg-primary" : "nameNarrateImage"} alt="" />
            </div>
        )
    }

    renderSubQuestion = (question, subQuestion, index, charactersLeft, maxCharacters, answersSaved) => {
        if(answersSaved.length > 0) {
            charactersLeft = (maxCharacters - answersSaved[subQuestion].length);
        }

        return (
            <div key={index} className="mb-2">
                {question.taskOption1 && <h6 className="text-left small_text">{question['taskOption' + subQuestion]}</h6>}
                {/* <br/>
                <h6 className="text-left small_text">Description:</h6> */}
                <textarea rows={3} maxLength={maxCharacters} className="form-control mb-1" id={"answer_description_" + question.id + '_' + subQuestion} onChange={() => this.handleQuestionAnswer(question.id, subQuestion)} value={answersSaved.length > subQuestion ? answersSaved[subQuestion] : ''} placeholder="Description" />
                <span className="float-left leftCharacters">Characters left: {charactersLeft}</span>
                <br/>
            </div>
        )
    }

    render() {
        const {showQuestion, questions, userAnswers, maxCharacters} = this.props;
        const {error} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);
        var answersSaved = [];
        var charactersLeft = maxCharacters;
        var subQuestions = [1,2,3];

        if(alreadyExistsIndex >= 0) {
            answersSaved = userAnswers[alreadyExistsIndex].answer.split('||');
        }

        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="questions font-weight-bold">Select any image to write about it</h5>
                    {/* <span className="small_text">Note: Your answers will be cleared if you change your selected image later </span> */}

                    <div className="row justify-content-center">
                        {questions.map((question, index) => (
                            this.renderTestView(question, index, showQuestion)
                        ))}
                    </div>
                    <hr/>

                    <h5 className="text-centre">Narrate about selected image here</h5>

                    <div className="row mt-2">
                        <div className="col py-2 px-3">
                            <div className="px-2">
                                <h6 className="text-left small_text">Title * :</h6>
                                <input type="text" className="form-control" placeholder="Title" id={"answer_title_" + question.id} onChange={() => this.handleQuestionAnswer(question.id, 0)} value={answersSaved.length > 0 ? answersSaved[0] : ''} />
                                <span className="errorMessage">{error}</span>
                            </div>
                            <img src={question.taskDesc} className="img-fluid mt-3 img-thumbnail" alt="" />
                        </div>
                        <div className="col form-group pt-2">
                            {/* Descriptions */}
                            {subQuestions && subQuestions.map((subQuestion, index) => (
                                this.renderSubQuestion(question, subQuestion, index, charactersLeft, maxCharacters, answersSaved)
                            ))}
                        </div>
                    </div>

                    {/* Show next or submit button based on question */}
                    <div className="align-center my-2">
                        {(questions.length - 1) !== showQuestion ? 
                            <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                        : 
                            <Button size={"large"} variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Cognizant;
