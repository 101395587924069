import React from 'react';
import {Button} from '@material-ui/core';
import { STATE_KEYS } from '../../../../assets/constants';

class DimensionsQuiz extends React.Component {

    handleQuestionAnswer = (e, question, subQuestion) => {
        e.preventDefault();
        var answer = e.target.value;
        var regExp = /^\d+$/; // Regular expression to check number

        if(answer === '' || regExp.test(answer)) {
            var {userAnswers, taskTime} = this.props;
            var existAnswers = ['', '', ''];
            var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question);

            // remove answer if alreay exists
            if(alreadyExistsIndex >= 0) {
                existAnswers = userAnswers[alreadyExistsIndex].answer;
                userAnswers.splice(alreadyExistsIndex, 1);
            }

            // Replace the previous answer with new one
            existAnswers[subQuestion] = answer;

            // Add news answer for question
            userAnswers.push({question: question, answer: existAnswers, timeTaken: taskTime});

            this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);
            this.props.handleSetState(STATE_KEYS.ERROR, '');

        //    console.log("User answers", userAnswers)
        } else {
            this.props.handleSetState(STATE_KEYS.ERROR, 'Invalid entry, Please enter number between from 0-9');
        }
    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions, error} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questions[showQuestion].id);
        var answerSaved = ['', '', ''];
        var subQuestions = [];

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        if(questions[showQuestion].taskDesc !== '') {
            subQuestions = questions[showQuestion].taskDesc.split('||');
        }

        return (
            <div>
                {/* Looping total answers */}
                <div className="row">
                    {subQuestions.map((subQuestion, index) => (
                        <div key={index} className="col form-group">
                            <img src={subQuestion} className="dimensionImage" alt="" />
                            <input className="form-control w-50 m-auto" maxLength={1} name={"exampleRadios" + subQuestion} onChange={(e) => this.handleQuestionAnswer(e, questions[showQuestion].id, index)} placeholder={"Answer"} value={answerSaved[index]}  />
                        </div>
                    ))}
                </div>

                <div className="text-center">
                    <span className="errorMessage">{error}</span>
                </div>
                
                {/* Show next or submit button based on question */}
                <div className="my-2 p-3">
                    {(questions.length - 1) !== showQuestion ? 
                        <Button variant="contained" color="primary" onClick={() => this.props.handleNextButton()}>Next</Button>
                    : 
                        <Button variant="contained" color="primary" onClick={() => this.props.handleTestSubmit()}>Submit</Button>
                    }
                </div>
            </div>
        )
    }
      
    render() {

        return (
            <div className="card">
                <div className="standardImagesContainer">
                    <h5>Standard Images</h5>
                    <img src={'https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/dimensions/std_A.jpg'} className="standardImage" alt="" />
                    <img src={'https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/dimensions/std_B.jpg'} className="standardImage" alt="" />
                    <img src={'https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/dimensions/std_C.jpg'} className="standardImage" alt="" />
                    <img src={'https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/dimensions/std_D.jpg'} className="standardImage" alt="" />
                </div>
                <hr className="my-1" />

                {this.renderTestView()}
            </div>
        );
    }
}

export default DimensionsQuiz;