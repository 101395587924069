import React from 'react';
import {connect} from 'react-redux';
import {Button} from '@material-ui/core';
import Images from '../../../../assets/images';
import {Server, ApiPaths} from '../../../../utils/Server';
import {
    mainLoadingTrue,
    mainLoadingFalse,
    displayError,
    displayMessage,
} from '../../../../redux/actions/Home';
import swal from 'sweetalert';
import Modal from 'react-modal';
import {VideoPlayer} from '../../../common/player';

const customStyles = {
    content: {
        top: '50%',
        left: '62%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-30%',
        transform: 'translate(-50%, -50%)',
    },
};

const introductionVideoLink =
    'https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/2conv_Introduction-video-output.mp4';

class TaskDays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dayType: 1,
            isModalOpen: false,
            day1Status: false,
            day2Status: false,
            message: '',
            isIntroVideoPlaying: false,
            showIntroVideo: true,
        };
    }

    componentDidMount() {
        this.handleGetDayStatus();
    }

    handleGetDayStatus = async () => {
        const {auth_user} = this.props;

        this.props.onLoadingTrue();

        var formData = new FormData();
        formData.append('userId', auth_user && auth_user.uid);

        // Api service
        await Server.get(ApiPaths.GET_DAY_STATUS + '/' + auth_user.uid)
            .then(response => {
                const {data} = response;
                if (data.statusCode === 200) {
                    this.setState({
                        day1Status: data.data.day1,
                        day2Status: data.data.day2,
                    });
                } else {
                    this.props.onDisplayError(data.message);
                }
            })
            .catch(err => {
                this.props.onDisplayError(err.message);
            });

        this.props.onLoadingFalse();
    };

    handleDayType = (dayType, dayStatus) => {
        if (dayStatus) {
            this.setState({dayType: dayType, isModalOpen: true, message: ''});
        } else {
            this.handleGetNextTask(dayType);
        }
    };

    handleCloseModal = () => {
        this.setState({isModalOpen: false});
    };

    handleGetNextTask = dayType => {
        const {auth_user} = this.props;

        this.setState({dayType: dayType}, async () => {
            this.props.onLoadingTrue();

            var formData = new FormData();
            formData.append('userId', auth_user && auth_user.uid);
            formData.append('dayType', dayType);

            // Api service
            await Server.post(ApiPaths.GET_NEXT_TASK, formData)
                .then(response => {
                    const {data} = response;
                    if (data.statusCode === 200) {
                        const {taskGroupData} = data.data;

                        if (
                            taskGroupData &&
                            Object.entries(taskGroupData).length > 0
                        ) {
                            // Redirect to next task
                            window.location.href =
                                '/task/' +
                                taskGroupData.id +
                                '/task-details/' +
                                taskGroupData.group_name
                                    .toLowerCase()
                                    .replace(/[ ]/g, '-');
                        } else {
                            swal({
                                title: 'Task not found',
                                text:
                                    'There is no task available right now, Please try again',
                                buttons: ['CANCEL', 'RETRY'],
                            }).then(willRetry => {
                                if (willRetry) {
                                    this.handleGetNextTask(dayType);
                                }
                            });
                        }

                        this.setState({message: ''});
                    } else {
                        this.setState({message: data.message});
                        // this.props.onDisplayError(data.message);
                    }
                })
                .catch(err => {
                    this.props.onDisplayError(err.message);
                });

            this.props.onLoadingFalse();
        });
    };

    renderDayInstructions = dayType => {
        if (dayType === 1) {
            return (
                <div>
                    <h4 className="header">DAY 1</h4>
                    <p className="para">
                        To check the consistency, the tasks are divided into 2
                        days and today you will be performing 11 tasks.
                    </p>
                    <p className="para">
                        1. Keep a mobile/camera with you as few of the
                        activities expects you to upload pictures. Take help
                        from others to click the pictures.
                    </p>
                    <p className="para">
                        2. Keep worksheets and a pen nearby as few activities
                        would need rough work.
                    </p>
                    <p className="para">
                        3. Keep 1 or 2 plain a4 sheets as one of the activity
                        requires you to write on it and upload a picture.
                    </p>
                    <p className="para">
                        4. Be honest and truthful in answering as many of the
                        tasks do not involve right or wrong answers.
                    </p>
                    <p className="para">5. Be yourself</p>
                </div>
            );
        } else {
            return (
                <div>
                    <h4 className="header">DAY 2</h4>
                    <p className="para">
                        1. Keep a mobile/camera with you as few of the
                        activities expects you to upload pictures. Take help
                        from others to click the pictures.
                    </p>
                    <p className="para">
                        2. Keep worksheets and a pen nearby as few activities
                        would need rough work.
                    </p>
                    <p className="para">
                        3. Be honest and truthful in answering as many of the
                        tasks do not involve right or wrong answers.
                    </p>
                    <p className="para">4. Be yourself</p>
                </div>
            );
        }
    };

    renderIntroVideo = () => {
        const {isIntroVideoPlaying} = this.state;

        return (
            <div className="container">
                <VideoPlayer
                    url={introductionVideoLink}
                    height={600}
                    playing={isIntroVideoPlaying}
                />

                <Button
                    className="py-3 px-4"
                    color="primary"
                    variant="contained"
                    onClick={() => this.setState({showIntroVideo: false})}
                >
                    {'Go To Tasks'}
                </Button>
            </div>
        );
    };

    renderTaskDays = () => {
        const {
            dayType,
            isModalOpen,
            day1Status,
            day2Status,
            message,
        } = this.state;

        return (
            <div className="container">
                <div className="row mt-4">
                    <div className="col" />
                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                        <div
                            className={
                                day1Status
                                    ? 'card dayCard'
                                    : 'card inActiveDayCard'
                            }
                            onClick={() => this.handleDayType(1, day1Status)}
                        >
                            <div className="card-body">
                                <img
                                    src={Images.day}
                                    className="dayImage"
                                    alt=""
                                />
                                <h1 className="dayTitle">DAY 1</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col" />
                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                        <div
                            className={
                                day2Status
                                    ? 'card dayCard'
                                    : 'card inActiveDayCard'
                            }
                            onClick={() => this.handleDayType(2, day2Status)}
                        >
                            <div className="card-body">
                                <img
                                    src={Images.day}
                                    className="dayImage"
                                    alt=""
                                />
                                <h1 className="dayTitle">DAY 2</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col" />
                </div>

                {message && message && (
                    <div className="row mt-5">
                        <div className="col text-center px-2">
                            <h4 className="header text-danger text-capitalize">
                                {message}
                            </h4>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col text-left description_card px-5">
                        <h5 className="header">GENERAL INSTRUCTIONS</h5>
                        <p className="para">
                            This is a self-evaluation test and now that you have
                            decided to take this up, it clearly shows your
                            interest to improve and grow. Before taking the
                            test, please read the Instructions carefully so that
                            the purpose is served better.
                        </p>
                        <p className="para">
                            1. This is to know what you actually are capable of
                            and what’s innate in you. So be as real as possible.
                        </p>
                        <p className="para">
                            2. The evaluation will be purely based on your
                            answers. We will be able to guide you in choosing
                            the right career, the right course for you and also
                            the best college to peruse that course. Hence it’s
                            only your answers which can lead us in the right
                            direction to guide you. So please be honest and
                            authentic. Don’t copy or take anyone’s help.
                        </p>
                        <p className="para">
                            3. Ensure that you have a stable internet
                            connection.
                        </p>
                        <p className="para">
                            4. Ensure that once u start taking the test, you are
                            not distracted or disturbed until the time you
                            finish the day’s activities.
                        </p>
                        <p className="para">
                            5. Stay calm and answer all the questions that are
                            asked and play all the games without any hesitations
                            or prejudice.
                        </p>
                    </div>
                </div>

                <Button
                    className="py-3 px-4"
                    color="primary"
                    variant="contained"
                    onClick={() => this.setState({showIntroVideo: true})}
                >
                    {'Play Introduction Video'}
                </Button>

                <div className="row">
                    <div className="col">
                        <Modal
                            isOpen={isModalOpen}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={() => this.handleCloseModal()}
                            style={customStyles}
                        >
                            <div className="p-2">
                                {this.renderDayInstructions(dayType)}

                                <div className="text-center mt-4">
                                    <Button
                                        className="py-3 px-4"
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                            this.handleGetNextTask(dayType)
                                        }
                                    >
                                        {'START TASK'}
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        if (this.state.showIntroVideo) {
            return this.renderIntroVideo();
        } else {
            return this.renderTaskDays();
        }
    }
}

const mapStatesToProps = state => {
    return {
        auth_user: state.home.auth_user,
    };
};

const mapActionsToProps = {
    onLoadingTrue: mainLoadingTrue,
    onLoadingFalse: mainLoadingFalse,
    onDisplayError: displayError,
    onDisplayMessage: displayMessage,
};

export default connect(mapStatesToProps, mapActionsToProps)(TaskDays);
