import React from 'react';
import {Button} from '@material-ui/core';
import AudioPlayer from '../../../common/player/AudioPlayer';
import { STATE_KEYS } from '../../../../assets/constants';

class TempoChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {playAudio: [false, false, false, false, false, false, false, false, false, false], disabledAudio: [false, false, false, false, false, false, false, false, false, false], audioEnded: [false, false, false, false, false, false, false, false, false, false], playSecondAudio: [false, false, false, false, false, false, false, false, false, false]};
    }

    handleQuestionAnswer = (e, question) => {
        e.preventDefault();
        var answer = e.target.value;
        var {userAnswers} = this.props;
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === question.id);

        // remove answer if alreay exists
        if(alreadyExistsIndex >= 0 || answer == 0) {
            userAnswers.splice(alreadyExistsIndex, 1);
        }

        if(answer != 0) {
            userAnswers.push({question: question.id, answer: answer});
        }

        // Add new answer for question
        this.props.handleSetState(STATE_KEYS.ANSWERS, userAnswers);

       // console.log("User answers", userAnswers)
    }

    handleAudioPlay = (showQuestion) => {
        var {playAudio, disabledAudio} = this.state;
        playAudio[showQuestion] = true;
        disabledAudio[showQuestion] = true;
        this.setState({playAudio: playAudio, disabledAudio: disabledAudio});
    }

    handleAudioEnded = (showQuestion) => {
        var {audioEnded, playAudio} = this.state;
        audioEnded[showQuestion] = true;
        playAudio[showQuestion] = false;
        this.setState({audioEnded: audioEnded, playAudio: playAudio});
    }

    renderTestView = () => {
        const {showQuestion, userAnswers, questions} = this.props;
        const {playAudio, audioEnded, playSecondAudio, disabledAudio} = this.state;
        const question = questions[showQuestion];
        var alreadyExistsIndex = userAnswers.findIndex(x => x.question === questions[showQuestion].id);
        var answerSaved = '0';

        if(alreadyExistsIndex >= 0) {
            answerSaved = userAnswers[alreadyExistsIndex].answer;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <h6>Original audio</h6>
                        <br/>
                        <AudioPlayer type={1} playing={playAudio[showQuestion]} disabled={disabledAudio[showQuestion]} url={question.taskDesc} onPlay={() => this.handleAudioPlay(showQuestion)} onEnded={() => this.handleAudioEnded(showQuestion)} />
                        <p className="small_text mt-4">{audioEnded[showQuestion] ? "You already listened to this audio, And you can't play it anymore" : "Original audio you can play and listen only once, it will be disabled once you click on play, no limit for modified audio"}</p>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <h6>Modified audio</h6>
                        <br/>

                        <AudioPlayer type={2} playing={playSecondAudio[showQuestion]} disabled={!audioEnded[showQuestion]} url={question.taskOption1} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 pt-5">
                        <div className="form-group">
                            <select name="taskOption" className="form-control" value={answerSaved} onChange={(e) => this.handleQuestionAnswer(e, question)}>
                                <option value="">Select tempo change</option>
                                <option value="1">Faster</option>
                                <option value="2">Slower</option>
                                <option value="3">No Change</option>
                            </select>

                            {/* Show next or submit button based on question */}
                            <div className="align-center my-4">
                                {(questions.length - 1) !== showQuestion ? 
                                    <Button size={"large"} variant="contained" color="primary" onClick={() => {
                                        if(audioEnded[showQuestion]) {
                                            this.props.handleNextButton()
                                        } else {
                                            alert('Listen to the current audio completely');
                                        }
                                    }}>Next</Button>
                                : 
                                    <Button size={"large"} variant="contained" color="primary" onClick={() => {
                                        if(audioEnded[showQuestion]) {
                                            this.props.handleTestSubmit()
                                        } else {
                                            alert('Listen to the current audio completely');
                                        }
                                    }}>Submit</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderTestView()}
                </div>
            </div>
        );
    }
}

export default TempoChange;