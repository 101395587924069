import React from 'react';
import {Toolbar, AppBar} from '@material-ui/core';
import { connect } from 'react-redux';
import { userLogout } from '../../../redux/actions/Home';
import swal from 'sweetalert';
class Header extends React.Component {

  handleLogOut = () => {
    swal({
      title: "Are you sure",
      text: "To logout of the current window",
      buttons: ["Cancel", "Logout"],
    })
    .then((willExit) => {
        if (willExit) {
          this.props.onUserLogout();
        }
    });
  }

  render() {
    const {auth_user} = this.props;

    return (
      <header className="AppHeader">
        <div className="row">
          <AppBar position="static">
            <Toolbar>
              <div className="col-md-3">
                <h6 className="headerTitle text-left">{auth_user.firstName}</h6>
              </div>
              <div className="col">
                <h6 className="headerTitle">{this.props.title}</h6>
              </div>
              <div className="col-md-3 text-right">
                <button className="btn btn-md btn-danger" onClick={() => this.handleLogOut()}><span className="small_text"><b>{'LOGOUT '}<i className="fa fa-sign-out"></i></b></span></button>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      </header>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
      auth_user: state.home.auth_user
  }
}

const mapActionsToProps = {
  onUserLogout: userLogout
}

export default connect(mapStatesToProps, mapActionsToProps) (Header);
